<template>
  <div class="box pointer">
    <div class="head-accordion-line flex ai-c jc-sb p-10" @click="show = !show">
      <div class="fw-b">{{ label }}</div>
      <div class="pointer">
        <BaseIcon class="ic-18 black" :class="{ reverse: show }" icon="arrow-down" />
      </div>
    </div>
    <div class="p-10 line-up" v-if="show" v-html="text"></div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref } from 'vue'
import { BaseIcon } from '@/components'

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  }
})

// Data
const { label, text } = toRefs(props)
const show = ref(false)
</script>

<style>
.line-up {
  border: var(--br);
}
.reverse {
  transform: rotate(180deg);
}
</style>
