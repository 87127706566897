import moment from 'moment-timezone'
import { nextTick, createApp } from 'vue'
import { BasePreview } from '@/components'
import { $busEmit } from './event-bus'
import store from '@/store/index.js'

export function formatDate(date) {
  moment.locale('ru')
  return date ? moment(date).format('DD MMMM YYYY') : ''
}
export function formatDateTime(date) {
  moment.locale('ru')
  return date ? moment(date).format('DD MMMM YYYY в HH:mm') : ''
}
export function formatRuDateTime(date) {
  moment.locale('ru')
  // return moment(date).isSame(moment(), 'day') ? 'Сегодня в ' + moment(date).format('hh:mm') : formatDateTime(date)
  const newDate = moment(date).tz('Asia/Almaty').format()
  return moment(newDate).isSame(moment(), 'day')
    ? 'Сегодня в ' + moment(newDate).format('HH:mm')
    : formatDateTime(newDate)
}
export function getDayCount(date) {
  const res = date ? moment(date).diff(moment(), 'days') : 0
  const days = res < 0 ? 0 : res
  const months = days > 30 && days < 365 ? Number(days / 30).toFixed(1) + ' месяц(ев)' : -1
  const years = days > 365 ? Number(days / 365).toFixed(1) + ' год(а)' : -1
  return months !== -1 ? months : years !== -1 ? years : days > 0 ? days + ' дня(ей)' : 0
}
export function cutFullName(fullname) {
  const arr = fullname.split(' ')
  return arr?.length ? arr[0] + ' ' + arr[1] : 'Без имени'
}
export function cutName(fullname) {
  const arr = fullname.split(' ')
  return arr?.length ? arr[0] + ' ' + arr[1] : 'Без имени'
}
export function getAvatar(avatar) {
  return avatar || require('@/assets/img/ava.svg')
}
export function getRuSumm(summ1, summ2) {
  return summ1 ? Number(summ1).toLocaleString('ru-RU') : Number(summ2).toLocaleString('ru-RU')
}
export function isInvalidForm(elementId) {
  let res = false
  const el = document.getElementById(elementId)
  const inputs = el.querySelectorAll('.required')
  let counter = 0

  for (let i = 0; i < inputs.length; i++) {
    const element = inputs[i]
    if (element.getAttribute('data-success') === 'false') {
      element.classList.add('error')
      if (counter === 0) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      res = true
      counter++
    } else {
      element.classList.remove('error')
    }
  }

  return res
}
export function isEmptyObject(object) {
  if (object) return true
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) return false
  }
  return true
}
export function disableAllBtns(element, state) {
  const modal = document.querySelector(element)
  const btns = modal?.querySelectorAll('.btn') || []
  for (let btn of btns) {
    if (state) {
      btn.classList.add('disabled')
      btn.disabled = true
    } else {
      btn.classList.remove('disabled')
      btn.disabled = false
    }
  }
}
export function callPrint(elementId) {
  const printCSS = '<link rel="stylesheet" href="css/print.css" type="text/css" />'
  const printContent = document.getElementById(elementId).innerHTML
  const windowPrint = window.open('', '', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0')
  windowPrint.document.write(printCSS)
  windowPrint.document.write(printContent)
  windowPrint.document.close()
  windowPrint.focus()
  windowPrint.print()
  windowPrint.close()
}
export function linksBlank() {
  const messages = document.querySelectorAll('.editor-data')

  if (messages) {
    messages.forEach((m) => {
      const links = m.querySelectorAll('a')

      links.forEach((l) => {
        if (!l.classList.contains('self')) {
          l.setAttribute('target', '_blank')
        }
      })
    })
  }
}
export function imagesClick() {
  nextTick(() => {
    const figures = document.querySelectorAll('figure.image')
    const images = []

    figures.forEach((el) => {
      const image = el.querySelector('img')
      if (image) images.push(image)
    })

    images.forEach((el) => {
      el.addEventListener('click', function () {
        $busEmit('imgAction', el.src)
      })
    })
  })
}
export function getLinkData(isClick = true) {
  const elements = document.querySelectorAll('.inner-link')

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i]

    // Hover
    element.onmouseover = function (e) {
      // Get data
      const hash = e.target.hash
      const coords = e.target.getBoundingClientRect()

      // Create element
      const ComponentApp = createApp(BasePreview, { hash, coords })
      const wrapper = document.createElement('div')
      wrapper.setAttribute('id', 'tooltip-info')
      ComponentApp.use(store)
      ComponentApp.mount(wrapper)

      // Set element
      document.body.appendChild(wrapper)
    }

    // Blur
    element.onmouseout = function () {
      // Remove element
      const el = document.getElementById('tooltip-info')
      document.body.removeChild(el)
    }

    // Click
    if (isClick) {
      element.addEventListener(
        'click',
        function (e) {
          e.preventDefault()
          e.stopPropagation()
          const hash = e.target?.hash.split('-')

          if (e.target?.hash.includes('user')) {
            // window.location.replace('https://intra.chocofamily.kz/app/user/' + hash[1])
            window.location.href = 'https://intra.chocofamily.kz/app/user/' + hash[1]
          } else {
            const url = window.location.origin
            window.location.href = `${url}/communities/single/${hash[1]}`
            // window.history.pushState({}, '', `/communities/single/${hash[1]}`)
          }
        },
        false
      )
    }
  }
}
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
export async function copyTextToClipboard(text) {
  let res = false
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  try {
    await navigator.clipboard.writeText(text)
    res = true
  } catch (error) {
    console.error('Async: Could not copy text: ', error)
  }
  return res
}
