<template>
  <aside class="base-nav grid ggap-10 gtr-auto-1">
    <div class="base-nav__links flex fd-c">
      <RouterLink to="/news" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
        <BaseIcon class="ic-20 grey" icon="flash" />
        Новости
      </RouterLink>
      <RouterLink to="/communities" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
        <BaseIcon class="ic-20 grey" icon="users" />
        Сообщества
      </RouterLink>
      <RouterLink to="/events" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
        <BaseIcon class="ic-20 grey" icon="trophy" />
        Мероприятия
      </RouterLink>
      <RouterLink to="/meeting-rooms" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
        <BaseIcon class="ic-20 grey" icon="work" />
        Переговорные
      </RouterLink>
      <!-- <img class="rounded overflow-h mt-10 w-100" src="@/assets/img/speaker-day.png" /> -->
    </div>
    <button v-if="isShow" class="base-nav__totop btn transparent-grey xlg jc-c" @click="toTop">
      <BaseIcon class="ic-20 grey" icon="arrow-up" />
    </button>
  </aside>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { BaseIcon } from '@/components'

// Data
const isShow = ref(false)

// Mounted
onMounted(() => {
  const el = document.querySelector('.default-main')
  if (el) {
    el.addEventListener('scroll', function () {
      if (el.scrollTop > 300) isShow.value = true
      else isShow.value = false
    })
  }
})

// Methods
function toTop() {
  const scrlContent = document.querySelector('.default-main')
  scrlContent.scroll({ top: 0, left: 0, behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
.chocofest-nav {
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
}
.base-nav {
  position: sticky;
  top: 0;
  width: 180px;
  height: 100%;
  max-height: calc(100vh - 70px);

  &__links {
    grid-gap: 2px;
    .btn {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__totop {
    height: auto !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}
</style>
