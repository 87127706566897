<template>
  <ModalWrapper v-if="isShowAll" @close="isShowAll = false" :isFoot="false">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <BaseIcon class="ic-24 grey" icon="users" />
        <h1 class="title">Все подписчики</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="users grid pb-20">
        <div class="p-20">
          <input
            type="search"
            class="form-item__input pl-15 pr-15 w-100"
            placeholder="Поиск по пользователям..."
            v-model="searchText"
          />
        </div>

        <template v-if="searchItems && searchItems.length">
          <div
            v-for="user of searchItems"
            :key="user.id"
            class="users__item grid gtc-auto-1 ggap-10 ai-c pl-20 pr-20 pt-10 pb-10"
          >
            <a :href="profileLink + user.id" class="cover ava-50">
              <img :src="user.avatar || require('@/assets/img/ava.svg')" id="avatar" />
            </a>

            <div class="grid ggap-5">
              <a :href="profileLink + user.id">{{ user.fullname }}</a>
              <div>
                {{ user.specialization || 'Безработный' }} в команде: <a href="#">{{ user.company || 'Ронинов' }}</a>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="ta-center">
          <h2 class="title mb-5">Упс, ничего не нашлось!</h2>
          <div>Либо вы просто допустили очепятку</div>
        </div>
      </div>
    </template>
  </ModalWrapper>
  <div class="base-users flex ai-c" :class="innerClass">
    <a
      v-for="item of filteredItems"
      :key="item.id"
      :href="'#user-' + item.id"
      class="inner-link cover ava-24 base-users__item"
      data-type="user"
    >
      <img :src="item.avatar || require('@/assets/img/ava.svg')" id="avatar" />
    </a>

    <a v-if="items && items.length > maxLength" href="#" class="ml-10" @click.prevent="isShowAll = true"
      >еще {{ count }}</a
    >
  </div>
</template>

<script setup>
import { defineProps, toRefs, computed, onMounted, ref, inject } from 'vue'
import { ModalWrapper, BaseIcon } from '@/components'
import { getLinkData } from '@/plugins'

// Props
const props = defineProps({
  innerClass: {
    type: String,
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  }
})

// Data
const { innerClass, items } = toRefs(props)
const maxLength = 16
const isShowAll = ref(false)
const searchText = ref('')
const profileLink = inject('profileLink')

// Computed
const count = computed(() => items.value.length - maxLength)
const filteredItems = computed(() =>
  items.value.length > maxLength ? items.value.filter((el, index) => index < maxLength) : items.value
)
const searchItems = computed(() => {
  const ser = searchText.value.toLocaleLowerCase()
  return searchText.value ? items.value.filter((el) => el.fullname.toLocaleLowerCase().includes(ser)) : items.value
})

// Mounted
onMounted(() => {
  setImages()
  getLinkData()
})

// Methods
function setImages() {
  const images = document.querySelectorAll('#avatar')
  for (let i = 0; i < images.length; i++) {
    const img = images[i]
    img.addEventListener('error', function (event) {
      event.target.src = require('@/assets/img/ava.svg')
      event.onerror = null
    })
  }
}
</script>

<style lang="scss" scoped>
.users {
  width: 700px;

  &__item {
    border-top: var(--b1);

    &:hover {
      background-color: var(--grey-el);
    }
  }
}
.base-users {
  &__item {
    margin-right: -2px;
    box-shadow: 0 0 0 2px var(--box);
    position: relative;
    z-index: 0;

    &:hover {
      z-index: 1;
    }

    img {
      pointer-events: none;
    }
  }
}
</style>
