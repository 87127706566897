import axios from '../axios'

class PostsApi {
  getAll(params) {
    if (params) return axios.get('/api/posts', { params })
    else return axios.get('/api/posts')
  }
  get(id) {
    return axios.get(`/api/posts/${id}`)
  }
  create(payload) {
    return axios.post('/api/posts', payload)
  }
  update(payload, id) {
    return axios.post(`/api/posts/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/posts/${id}`)
  }
  getPostsTags() {
    return axios.get('/api/tags/key/posts')
  }
  setCounter(id) {
    return axios.post(`/api/posts/${id}/view`)
  }
  toggleLike(id) {
    return axios.post(`/api/posts/${id}/like`)
  }
}

export default new PostsApi()
