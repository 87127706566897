import { createStore } from 'vuex'
import { users } from '@/api'

// Api modules
import main from '@/api/modules/main'

// Vuex modules
import helpers from './modules/helpers'
import notifications from './modules/notifications'
import news from './modules/news'
import communities from './modules/communities'
import events from './modules/events'
import meetingRooms from './modules/meetingRooms'

const defaultState = () => {
  return {
    timerID: -1,
    timer: 7200000,
    theme: 'light',
    prefix: 'choco',
    token: JSON.parse(localStorage.getItem('choco_token')) || null,
    profile: JSON.parse(localStorage.getItem('choco_profile')) || null,
    user: null,
    users: [],
    isMobile: false
  }
}

export default createStore({
  state: defaultState(),

  mutations: {
    SET_IS_MOBILE(state, payload) {
      state.isMobile = payload
    },
    CLEAR_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_TIMER(state, payload) {
      state.timer = payload
    },
    SET_TIMERID(state, payload) {
      state.timerID = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_AUTH(state, payload) {
      state.auth = payload
    },
    SET_PROFILE(state, payload) {
      state.profile = payload
    },
    SET_USERS(state, payload) {
      const users = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        if (item.id !== state?.profile?.id) {
          users.push({
            id: item.id,
            text: item.fullname,
            position: item.specialization || 'Нет должности',
            value: item.id,
            avatar: item.avatar
          })
        }
      }
      state.users = users
    }
  },

  actions: {
    GET_PROFILE({ getters, commit }) {
      return main.getProfile().then(({ data }) => {
        localStorage.setItem(`${getters.prefix}_profile`, JSON.stringify(data.data))
        commit('SET_PROFILE', data.data)
      })
    },
    GET_USERS({ commit }, payload) {
      if (payload) {
        return users
          .getAll(payload)
          .then(({ data }) => {
            commit('SET_USERS', data.data)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        return new Promise((resolve) => resolve())
      }
    }
  },

  getters: {
    timerID: (s) => s.timerID,
    timer: (s) => s.timer,
    prefix: (s) => s.prefix,
    token: (s) => s.token,
    profile: (s) => s.profile,
    user: (s) => s.user,
    users: (s) => s.users,
    isMobile: (s) => s.isMobile
  },

  modules: { helpers, notifications, news, communities, events, meetingRooms }
})
