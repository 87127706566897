export default [
  {
    path: 'news',
    meta: {
      title: 'Новости'
    },
    component: () => import(/* webpackChunkName: "views-news" */ '@/views/news/index.vue'),
    children: [
      {
        path: ':postId',
        meta: {
          title: 'Пост'
        },
        component: () => import(/* webpackChunkName: "views-post" */ '@/components/post/View.vue')
      },
      // {
      //   path: 'add',
      //   meta: {
      //     title: 'Новая запись'
      //   },
      //   component: () => import(/* webpackChunkName: "views-news-add" */ '@/components/post/Index.vue')
      // },
      {
        path: 'edit/:postId',
        meta: {
          title: 'Редактирование поста'
        },
        component: () => import(/* webpackChunkName: "views-news-edit" */ '@/components/post/Index.vue')
      }
      // {
      //   path: 'view/:id',
      //   meta: {
      //     title: 'Просмотр заявки'
      //   },
      //   component: () => import(/* webpackChunkName: "page-orders-view" */ '@/views/orders/order-view.vue')
      // },
      // {
      //   path: 'print/:id',
      //   meta: {
      //     title: 'Печать заявки'
      //   },
      //   component: () => import(/* webpackChunkName: "page-orders-print" */ '@/views/orders/order-print.vue')
      // }
    ]
  }
]
