<template>
  <!-- <div class="comments__item-el grid"> -->
  <div v-if="item" class="comments__item grid gtc-auto-1 ggap-10 pt-10" :class="{ 'pl-40': isChild }">
    <!-- Avatar -->
    <a v-if="item.user" :href="profileLink + item.user.id" class="comments__item-ava cover ava-30 bordered">
      <img :src="item.user.avatar" />
    </a>

    <!-- Data -->
    <div class="comments__item-data">
      <!-- Top -->
      <div class="flex ai-c jc-sb pos-r z0">
        <div v-if="item.user" class="flex ai-c ggap-10">
          <a :href="profileLink + item.user.id" class="comments__item-user">{{ item.user.fullname }}</a>
          <div v-if="item.recipient && !isMobile" class="comments__item-reply flex ai-c ggap-5">
            ответил(а): <a :href="profileLink + item.recipient.id">{{ item.recipient.fullname }}</a>
          </div>
        </div>
        <!-- <button class="comments__item-report pos-a btn sm transparent-grey pl-10 pr-10" v-tippy="'Пожаловаться'">
          <BaseIcon class="ic-16 grey" icon="alert" />
        </button> -->
      </div>

      <!-- Message -->
      <div
        class="comments__item-message pr-40"
        v-html="DOMPurify.sanitize(item.body)"
        :class="{ wrapped: !isUnwrapped && canUnwrap }"
      />
      <small v-if="item.body && canUnwrap" class="pointer pb-10 primary-color-text" @click="isUnwrapped = !isUnwrapped">
        {{ isUnwrapped ? 'Свернуть' : 'Развернуть' }}
      </small>

      <!-- Actions -->
      <div class="comments__item-actions flex ai-c jc-sb pb-5">
        <div class="flex ai-c ggap-10">
          <small>{{ formatRuDateTime(item.created_at) }}</small>
          <a href="#" @click.prevent="formToggler">Ответить</a>
        </div>

        <!-- Like -->
        <button
          class="comments__item-like btn sm pl-10 pr-10 fs-12 ggap-5"
          :class="{ has: likeCount, secondary: isLiked, 'transparent-grey': !isLiked }"
          @click.prevent="likeToggler"
        >
          <BaseIcon class="ic-14" :class="isLiked ? 'white' : 'grey'" icon="heart" />
          {{ likeCount ? likeCount : '' }}
        </button>
      </div>

      <Form
        v-if="itemModel.reply"
        :postId="postId"
        :parentId="parentId"
        :recipientId="item.user.id"
        class="mb-10"
        @updatePost="emits('updatePost')"
      />
    </div>
  </div>

  <template v-if="item.child && item.child.length && item.child[0]">
    <Item
      v-for="subitem of item.child"
      :key="subitem.id"
      :item="subitem"
      :postId="postId"
      :parentId="parentId"
      isChild
      @formOpen="emits('formOpen', subitem.id)"
      @updatePost="emits('updatePost')"
    />
  </template>
  <!-- </div> -->
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed, ref, inject } from 'vue'
import { BaseIcon } from '@/components'
import { formatRuDateTime } from '@/plugins'
import Item from './Item.vue'
import Form from './Form.vue'
import comments from '@/api/modules/comments'
import DOMPurify from 'dompurify'
import { useStore } from 'vuex'

// Emits
const emits = defineEmits(['update:item', 'formOpen', 'updatePost'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  isChild: {
    type: Boolean,
    default: false
  },
  postId: {
    type: Number,
    default: 0
  },
  parentId: {
    type: Number,
    default: 0
  }
})

// Data
const { item, postId, parentId } = toRefs(props)
const isLiked = ref(false)
const likeCount = ref(0)
const isUnwrapped = ref(false)
const profileLink = inject('profileLink')
const store = useStore()

// Computed
const isMobile = computed(() => store.getters.isMobile)
const itemModel = computed({
  get: () => item.value,
  set: (val) => emits('update:item', val)
})
const canUnwrap = computed(() => {
  const el = document.createElement('div')
  el.innerHTML = DOMPurify.sanitize(item.value.body)
  return item.value?.body?.length > 480 || el.querySelectorAll('p').length > 6
})

// Created
isLiked.value = item.value.liked
likeCount.value = item.value.likes_count

// Methods
function formToggler() {
  itemModel.value.reply = itemModel.value?.reply ? !itemModel.value.reply : true
  emits('formOpen', itemModel.value.id)
}
function likeToggler() {
  isLiked.value = !isLiked.value
  likeCount.value = isLiked.value ? likeCount.value + 1 : likeCount.value - 1 !== -1 ? likeCount.value - 1 : 0

  if (isLiked.value) {
    comments.like(item.value.id)
  } else {
    comments.unlike(item.value.id)
  }
}
</script>
