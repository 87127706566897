import axios from '../axios'

class SearchApi {
  community(searchText) {
    return axios.get(`/api/search/community?s=${searchText}`)
  }
  user(searchText) {
    return axios.get(`/api/search?fullname=${searchText}`)
  }
}

export default new SearchApi()
