import axios from '../axios'

class EventsApi {
  getAll(params) {
    if (params) return axios.get('/api/event', { params })
    else return axios.get('/api/event')
  }
  get(id) {
    return axios.get(`/api/event/${id}`)
  }
  create(payload) {
    return axios.post('/api/event', payload)
  }
  update(payload, id) {
    return axios.post(`/api/event/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/event/${id}`)
  }

  // Questions
  createQuestion(payload) {
    return axios.post('/api/event/question', payload)
  }
  likeQuestion(id) {
    return axios.post(`/api/event/question/like/${id}`)
  }
  dislikeQuestion(id) {
    return axios.post(`/api/event/question/dislike/${id}`)
  }
  deleteQuestion(id) {
    return axios.delete(`/api/event/question/${id}`)
  }

  // Comments
  getComments(id, type) {
    const all = type === 'all' ? '?all=true' : ''
    return axios.get(`/api/event/comment/${id}${all}`)
  }
  createComment(payload) {
    return axios.post('/api/event/comment', payload)
  }
  deleteComment(id) {
    return axios.delete(`/api/event/comment/${id}`)
  }
}

export default new EventsApi()
