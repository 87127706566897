<template>
  <div class="tab-buttons flex box">
    <button
      v-for="item of items"
      :key="item.value"
      type="button"
      class="tab-buttons__item h-100 pl-10 pr-10 flex ai-c jc-c ggap-10"
      :class="{ active: propValue === item.value }"
      @click="setCurrentTab(item.value)"
    >
      <slot name="item" :item="item">
        <template v-if="item.icon">
          <BaseIcon class="ic-16 grey" :icon="item.icon" />
        </template>
        <template v-if="item.text">
          {{ item.text }}
        </template>
      </slot>
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps(['items', 'modelValue'])

// Data
const { items, modelValue } = toRefs(props)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Methods
function setCurrentTab(value) {
  propValue.value = value
}
</script>

<style lang="scss" scoped>
.tab-buttons {
  height: 36px;
  overflow: hidden;

  &.full-width {
    justify-content: space-evenly;
  }
  &.full-width &__item {
    width: 100%;
  }

  &.full-height,
  &.full-height &__item {
    height: 100%;
  }
  &.full-height &__item.active {
    box-shadow: inset 0 -2px 0 0 var(--primary);
  }

  &.no-border {
    border: 0;
  }

  &__item {
    background-color: transparent;
    border: 0;
    height: 100%;
    color: #999;
    cursor: pointer;

    &:hover {
      color: var(--text);
    }

    &.active {
      color: var(--text);
      background-color: var(--grey);
      // box-shadow: 0 2px 0 0 var(--primary);
      font-weight: bold;
    }
  }
}
</style>
