<template>
  <form class="comments__form grid gtc-auto-1 ggap-10" @submit.prevent="sendAction">
    <a :href="profileLink + profile.id" class="comments__item-ava cover ava-30">
      <img :src="profile.avatar" />
    </a>
    <div class="grid gtc-1-auto ggap-5">
      <FormEditor
        label=""
        placeholder="Комментарий..."
        v-model="formData.name"
        required
        :disabled="isLoad"
        class="sm"
        @enterAction="sendAction"
      />
      <button
        class="btn grey pl-10 pr-10 sm"
        type="submit"
        :disabled="isLoad"
        v-tippy="'Нажмите Ctrl + Enter чтобы отправить'"
      >
        <BaseLoad v-if="isLoad" class="pos-r sm" />
        <BaseIcon v-else class="ic-16 primary" icon="send-solid" />
      </button>
      <label for="anonim" class="flex ai-c ggap-5 pointer">
        <input id="anonim" v-model="formData.anonim" type="checkbox" />
        <div>Отправить анонимно</div>
      </label>
    </div>
  </form>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, computed, toRefs, ref, inject } from 'vue'
import { BaseIcon, BaseLoad, FormEditor } from '@/components'
import events from '@/api/modules/events'

// Emits
const emits = defineEmits(['updateQuestion'])

// Props
const props = defineProps({
  questionId: {
    type: Number,
    default: 0
  },
  parentId: {
    type: Number,
    default: 0
  }
})

// Data
const store = useStore()
const formData = ref({ name: '', anonim: true })
const isLoad = ref(false)
const profileLink = inject('profileLink')
const { questionId, parentId } = toRefs(props)
console.log(questionId.value, parentId.value)
// Computed
const profile = computed(() => store.getters.profile)

// Methods
function sendAction() {
  if (!formData.value?.name) return
  isLoad.value = true
  const cleanedMessage = formData.value?.name.split('<p>&nbsp;</p>').join('')

  if (cleanedMessage.trim()) {
    const payload = new FormData()
    parentId.value && payload.append('parent_id', parentId.value)
    !formData.value?.anonim && payload.append('user_id', profile.value?.id)
    payload.append('event_question_id', questionId.value)
    payload.append('name', cleanedMessage)

    events
      .createComment(payload)
      .then(() => {
        emits('updateQuestion')
        formData.value.name = ''
      })
      .finally(() => {
        isLoad.value = false
      })
  }
}
</script>

<style lang="scss">
.comments__item {
  position: relative;
}
.comments__delete {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--rgba-red-08);
  backdrop-filter: blur(5px);
}
.comments__form {
  &.sticky {
    position: sticky;
    bottom: 0;
    background: var(--box);
    padding: 10px;
    border-radius: 0 0 var(--br) var(--br);
    margin: 0 -10px -10px -10px;
  }
}
</style>
