<template>
  <teleport to="body">
    <div
      v-if="isOpen"
      class="modal modal__media grid"
      :class="{ [currentItem.type]: true, sm: isSmall }"
      @click.stop
      @scroll="scrollAction"
    >
      <div :id="'modal__content-' + uid" class="modal__content" @click.stop>
        <!-- Actions -->
        <div class="modal__actions ggap-10">
          <!-- <button
            v-if="!(items && items.length === 1 && items[0].type === 'video')"
            class="btn outline sm grey pl-10 pr-10"
            @click.prevent="isSmall = !isSmall"
          >
            <BaseIcon class="ic-20 black" icon="fullscreen" />
          </button> -->
          <button class="btn outline sm grey pl-10 pr-10 z99999" @click.prevent="isOpen = false">
            <BaseIcon class="ic-20 black" icon="close" />
          </button>
        </div>

        <!-- Selected -->
        <div class="modal__content-current grid" :class="{ small: isSmall, single: !items || items.length === 1 }">
          <!-- Arrows -->
          <template v-if="items && items.length > 1">
            <button class="modal__btn prev" @click.prevent="changeCurrent('prev')">
              <div class="btn outline rounded cube-30">
                <BaseIcon class="ic-20 black" icon="arrow-left" />
              </div>
            </button>
            <button class="modal__btn next" @click.prevent="changeCurrent('next')">
              <div class="btn outline rounded cube-30">
                <BaseIcon class="ic-20 black" icon="arrow-right" />
              </div>
            </button>
          </template>

          <!-- Image -->
          <img v-if="currentItem.type === 'image'" :src="currentItem.url" class="m-auto" />

          <!-- Video -->
          <iframe
            v-else-if="currentItem.type === 'video'"
            :src="`https://www.youtube.com/embed/${currentItem.url}`"
            title="Jonas Brothers – sucker || polarrana cover"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <!-- List -->
        <div
          v-if="items && items.length > 1"
          class="modal__list ai-c ggap-5 p-10"
          :class="{ scrolled: items && items.length > 10 }"
        >
          <div
            v-for="item of items"
            :key="item.id"
            class="modal__list-item cover pos-r z1"
            :class="{ active: currentItem.id === item.id }"
            @click.prevent="currentItem = item"
          >
            <small v-if="item.type === 'video'" class="modal__list-item-marker flex ai-c ggap-5">
              <BaseIcon class="ic-14 grey" icon="play" />
              {{ item.info && item.info.time ? item.info.time : '00:00' }}
            </small>

            <img v-if="item.type === 'image'" :src="item.url" />
            <img
              v-else-if="item.type === 'video'"
              :src="
                item.info && item.info.thumbnails && item.info.thumbnails.default
                  ? item.info.thumbnails.default
                  : item.info.thumbnails.standard
              "
            />
          </div>
        </div>
      </div>
      <div class="modal__close" :style="closeHeight ? { height: closeHeight + 'px' } : ''" @click.stop="closeAction" />
    </div>
  </teleport>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed, watch } from 'vue'
import { BaseIcon } from '@/components'
import { $busOn } from '@/plugins'

// Data
const isOpen = ref(false)
const isSmall = ref(false)
const currentItem = ref(null)
const items = ref([])
const closeHeight = ref(0)
const uid = getCurrentInstance().uid
const el = computed(() => document.querySelector('#modal__content-' + uid))

// Watch
watch(isSmall, (val) => {
  localStorage.setItem('choco_media', val)
})

// Created
if (localStorage.getItem('choco_media')) {
  isSmall.value = JSON.parse(localStorage.getItem('choco_media'))
}
$busOn('openMedia', (data) => {
  isOpen.value = true
  items.value = data.arr
  currentItem.value = data.arr.find((el) => el === data.selected || el.id === data.selected)
})

// Mounted
onMounted(() => {
  if (el?.value) el.value?.addEventListener('DOMSubtreeModified', setHeight, false)

  document.addEventListener('keydown', function (event) {
    if (event.key === 'ArrowRight' || event.code === 'ArrowRight' || event.keyCode === 39) {
      changeCurrent('next')
    } else if (event.key === 'ArrowLeft' || event.code === 'ArrowLeft' || event.keyCode === 37) {
      changeCurrent('prev')
    }

    if (event.key === 'Escape' || event.code === 'Escape' || event.keyCode === 27) {
      isOpen.value = false
    }
  })
})

// Methods
function closeAction() {
  isOpen.value = false
}
function scrollAction() {
  if (el.value?.scrollHeight >= closeHeight.value) setHeight()
}
function setHeight() {
  closeHeight.value = el.value?.scrollHeight ? el.value.scrollHeight + 100 : 0
}
function changeCurrent(direction) {
  const index = items.value.findIndex((el) => el.id === currentItem.value.id)
  if (direction === 'next') {
    if (items.value.length - 1 !== index) {
      currentItem.value = items.value[index + 1]
    } else {
      currentItem.value = items.value[0]
    }
  } else {
    if (index !== 0) {
      currentItem.value = items.value[index - 1]
    } else {
      currentItem.value = items.value[items.value.length - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  padding: 0;
  z-index: 99999;
  backdrop-filter: blur(5px);

  &::before,
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 20px;
  }

  &__close {
    position: absolute;
    z-index: -1;
    width: 100%;
    min-height: 100vh;
  }

  &__btn {
    position: fixed;
    transform: translateY(-50%);
    top: 45%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    padding: 0;

    &.prev {
      left: 40px;
    }
    &.next {
      right: 40px;
    }
  }

  &__content {
    background-color: var(--form);
    margin: auto;
    border-radius: var(--br);

    &-current {
      min-height: 480px;
      margin: -40px auto 0;
      border-radius: var(--br) var(--br) 0 0;
      overflow: hidden;
      position: relative;

      img {
        width: auto;
        max-width: 100%;
        min-width: 30vw;
        height: auto;
        max-height: 80vh;
        min-height: 30vh;
        object-fit: contain;
      }

      iframe {
        width: 100%;
        height: 100%;
      }

      &.single {
        border-radius: var(--br);
      }

      &.small {
        img {
          max-height: calc(100vh - 180px);
        }

        iframe {
          margin: auto;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    position: sticky;
    top: 0;
    height: 40px;
  }

  &__list {
    overflow-x: auto;
    display: flex;
    position: sticky;
    bottom: 0;
    background-color: var(--box);
    border-top: var(--b1);
    justify-content: center;
    border-radius: 0 0 var(--br) var(--br);

    &.scrolled {
      display: -webkit-box;
    }

    &-item {
      width: 90px;
      height: 59px;
      background-color: #000;
      border-radius: 4px;

      &:hover img {
        opacity: 0.5;
      }

      &.active {
        box-shadow: 0 0 0 4px var(--primary);

        img {
          opacity: 0.5;
        }
      }

      &-play {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: calc(100% - 300px);
        height: calc(100% - 300px);
        min-width: 30px;
        min-height: 30px;
      }

      &:hover &-play {
        opacity: 1;
      }

      &-marker {
        position: absolute;
        right: 5px;
        bottom: 5px;
        background-color: #fff;
        color: #000;
        z-index: 1;
        padding: 4px 6px;
        border-radius: 4px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__actions,
  &__list,
  &__content {
    width: 1440px;
    max-width: calc(100vw - 80px);
    min-width: 940px;
  }

  &.video &__actions,
  &.video &__list,
  &.video &__content,
  &.sm &__actions,
  &.sm &__list,
  &.sm &__content {
    width: 940px;
  }
}

@media screen and (max-width: 800px) {
  .modal {
    &__actions {
      z-index: 9;
    }
    &__content {
      width: 95%;
      max-width: 95%;
      min-width: 95%;
    }
    &__actions,
    &__list {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
    &__btn {
      position: absolute;
      bottom: 0;
      width: 50vw;
      height: 50vh;
      border: 0;
      width: auto;
      max-width: 100%;
      min-width: 50vw;
      height: 100%;
      opacity: 0;
      padding: 0;
      user-select: none;

      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
    }
  }
}
</style>
