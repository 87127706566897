<template>
  <div class="item-read flex fd-c jc-fs ggap-5">
    <small v-if="label" class="item-read__label">{{ label }}</small>
    <slot>some data</slot>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps(['label'])
const { label } = toRefs(props)
</script>

<style lang="scss" scoped>
.item-read {
  &__label {
    font-size: 12px;
    color: #999;
  }
}
</style>
