<template>
  <div
    class="form-item grid editor"
    :id="'ckeditor-events-handler-' + uid"
    :class="{ required: required, disabled: disabled, focused: isFocused }"
    :data-success="isSuccess"
  >
    <div v-if="label" class="flex ai-c jc-sb">
      <label class="form-item__label">
        <BaseIcon v-if="helper" class="form-item__help ic-14 grey" icon="alert" v-tippy="helper" />
        {{ label }}
      </label>
      <small v-if="maxlength" class="fs-12"
        ><b :class="{ 't-red': propValue.length > maxlength }">{{ propValue.length }}</b
        >/{{ maxlength }}</small
      >
    </div>
    <ckeditor
      :editor="editor.type"
      :config="editor.config"
      :disabled="disabled"
      v-model="propValue"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
  </div>
</template>

<script setup>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { defineEmits, defineProps, toRefs, computed, ref, onMounted, getCurrentInstance } from 'vue'
import { BaseIcon } from '@/components'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:modelValue', 'enterAction'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  helper: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  minlength: {
    type: Number,
    default: 0
  },
  maxlength: {
    type: Number,
    default: 0
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  }
})

// Data
const { label, placeholder, required, helper, minlength, maxlength, modelValue } = toRefs(props)
const uid = getCurrentInstance().uid
const isFocused = ref(false)
const editor = {
  type: ClassicEditor,
  config: {
    toolbar: false,
    removePlugins: [
      'CKFinderUploadAdapter',
      'CKFinder',
      'EasyImage',
      'Image',
      'ImageCaption',
      'ImageStyle',
      'ImageToolbar',
      'ImageUpload',
      'MediaEmbed'
    ],
    placeholder: placeholder.value || 'Комментарий...',
    fillEmptyBlocks: false,
    tabSpaces: 0,
    link: {
      addTargetToExternalLinks: true,
      decorators: {
        detectInnerLinkUser: {
          mode: 'automatic',
          callback: (url) => url?.startsWith('#user'),
          attributes: {
            class: 'inner-link',
            'data-type': 'user'
          }
        },
        detectInnerLinkCommunity: {
          mode: 'automatic',
          callback: (url) => url?.startsWith('#community'),
          attributes: {
            class: 'inner-link',
            'data-type': 'community'
          }
        }
      }
    }
  }
}

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => {
    let str = val.replace('<p>&nbsp;</p>', '')
    str = DOMPurify.sanitize(str)
    str = str.replace('&nbsp;', '')
    emits('update:modelValue', str)
  }
})
const isSuccess = computed(() => {
  let res = false
  if (propValue.value) {
    res =
      maxlength.value || minlength.value
        ? propValue.value.length <= maxlength.value && propValue.value.length >= minlength.value + 7
        : !!propValue.value
  }
  return res
})

// Created
onMounted(() => {
  const editor = document.getElementById('ckeditor-events-handler-' + uid)

  editor.addEventListener('keydown', function (event) {
    if (event.ctrlKey && (event.key === 'Enter' || event.code === 'Enter' || event.keyCode === 13)) {
      emits('enterAction')
    }
  })
})
</script>

<style lang="scss">
.editor {
  &.extra-big {
    .ck-editor__main > .ck-editor__editable {
      min-height: 200px;
    }
  }
  &.big {
    .ck-editor__main > .ck-editor__editable {
      min-height: 100px;
    }
  }

  &.sm {
    .ck-editor__main > .ck-editor__editable {
      padding: 1px 10px !important;
    }
  }

  &.empty {
    .ck-editor__main > .ck-editor__editable {
      border: 0 !important;
      max-height: 400px;
      padding: 0 !important;
      background-color: transparent !important;
      font-size: 16px;

      &:hover,
      &.ck-focused {
        border: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  &.focused {
    .ck .ck-placeholder:before,
    .ck.ck-placeholder:before {
      opacity: 0.5;
    }
  }

  .ck {
    &-editor__top {
      display: none !important;
    }

    &-editor__main > .ck-editor__editable {
      max-height: 200px;
      border: var(--b1) !important;
      border-radius: var(--br) !important;
      background-color: var(--form) !important;
      padding: 5px 10px !important;
      word-break: break-word;
      // overflow-wrap: break-word;
      // word-wrap: break-word;

      &:hover {
        border: 1px var(--bcolor-d) solid !important;
        box-shadow: inset 0 0 0 1px var(--bcolor-d) !important;
      }
      &.ck-focused {
        border: 1px var(--primary) solid !important;
        box-shadow: inset 0 0 0 1px var(--primary) !important;
      }

      p {
        margin: 5px 0 !important;

        & > .image-inline {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
