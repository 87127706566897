export default [
  {
    path: 'communities',
    component: () => import(/* webpackChunkName: "views-communities" */ '@/views/communities/index.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Сообщества'
        },
        component: () => import(/* webpackChunkName: "views-communities" */ '@/views/communities/list.vue')
      },
      {
        path: 'my',
        meta: {
          title: 'Мои сообщества'
        },
        component: () => import(/* webpackChunkName: "views-communities-my" */ '@/views/communities/list.vue')
      },
      {
        path: 'subscribed',
        meta: {
          title: 'Мои подписки'
        },
        component: () => import(/* webpackChunkName: "views-communities-subscribed" */ '@/views/communities/list.vue')
      },
      {
        path: 'single/:id',
        meta: {
          title: 'Сообщество'
        },
        component: () => import(/* webpackChunkName: "views-communities-single" */ '@/views/communities/single.vue'),
        children: [
          {
            path: ':postId',
            meta: {
              title: 'Пост'
            },
            component: () => import(/* webpackChunkName: "components-post-view" */ '@/components/post/View.vue')
          }
        ]
      }
    ]
  }
]
