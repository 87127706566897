import axios from '../axios'

class NotificationsApi {
  count() {
    return axios.get(`/api/notification/count-not-read`)
  }
  getAll(params) {
    return axios.get(`/api/notification`, { params })
  }
  readSingle(id) {
    return axios.patch(`/api/notification/${id}`)
  }
  readAll() {
    return axios.patch(`/api/notification/`)
  }
}

export default new NotificationsApi()
