<template>
  <div class="form-switch">
    <input
      type="checkbox"
      :name="'switch-' + uid"
      :id="'switch-' + uid"
      class="form-switch__input"
      v-model="propValue"
    />
    <label :for="'switch-' + uid" class="form-switch__label">&nbsp;</label>
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, computed } from 'vue'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  // required: {
  //   type: Boolean,
  //   default: false
  // },
  // disabled: {
  //   type: Boolean,
  //   default: false
  // },
  modelValue: {
    type: Boolean,
    default: false
  }
})

// Data
const { modelValue } = toRefs(props)
const uid = getCurrentInstance().uid

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
</script>

<style lang="scss" scoped>
.form-switch {
  $self: &;

  &__input {
    display: none;
  }
  &__label {
    display: block;
    background-color: #ccc;
    width: 34px;
    height: 20px;
    border-radius: 100px;
    position: relative;
    transition: 0.3s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      top: 3px;
      bottom: 2px;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 100px;
      transition: 0.3s;
    }

    #{$self}__input:checked + & {
      background-color: var(--primary);
      transition: 0.3s;
    }
    #{$self}__input:checked + &::before {
      left: 16px;
      transition: 0.3s;
    }
  }
}
</style>
