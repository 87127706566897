<template>
  <div class="tab-swipe flex ggap-20">
    <slot name="start" />

    <button
      v-for="item of items"
      :key="item.value"
      type="button"
      class="tab-swipe__item h-100 fw-b"
      :class="{ active: propValue === item.value }"
      @click="setCurrentTab(item.value)"
      :style="{ color: item.color }"
    >
      <slot name="item" :item="item">
        {{ item.text }}
      </slot>
    </button>

    <slot name="end" />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps(['items', 'modelValue'])

// Data
const { items, modelValue } = toRefs(props)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Methods
function setCurrentTab(value) {
  propValue.value = value
}
</script>

<style lang="scss" scoped>
.tab-swipe {
  border-bottom: 2px var(--bcolor) solid;

  &.full-width {
    justify-content: space-evenly;
  }
  &.full-width &__item {
    width: 100%;
  }

  &.full-height,
  &.full-height &__item {
    height: 100%;
  }
  &.full-height &__item.active {
    box-shadow: inset 0 -2px 0 0 var(--primary);
  }

  &.no-border {
    border: 0;
  }

  &__item {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 30px;
    color: #999;
    cursor: pointer;

    &:hover {
      color: var(--text);
    }

    &.active {
      color: var(--text);
      box-shadow: 0 2px 0 0 var(--primary);
      font-weight: bold;
    }
  }
}
</style>
