import { communities, search } from '@/api'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    pages: null
  }),
  mutations: {
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    CONCAT_ROWS(state, payload) {
      state.rows = [...state.rows, ...payload]
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    PUSH_ITEM(state, payload) {
      state.rows.unshift(payload)
    },
    CHANGE_ITEM(state, { id, payload }) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    REMOVE_ITEM(state, id) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1)
    }
  },
  actions: {
    GET_ROWS({ commit }, params) {
      return communities.getAll(params).then(({ data }) => {
        const pages = {
          ...data?.meta,
          totalPages: Math.ceil(data?.meta?.total / data?.meta?.per_page)
        }
        commit('SET_PAGES', pages)

        const payload = data?.data
        if (!params?.page || Number(params?.page) === 1) commit('SET_ROWS', payload)
        else commit('CONCAT_ROWS', payload)
      })
    },
    GET_ROWS_MY({ commit }, params) {
      return communities.getAllMy(params).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        commit('SET_PAGES', pages)

        const payload = data.data
        if (!params?.page || Number(params?.page) === 1) commit('SET_ROWS', payload)
        else commit('CONCAT_ROWS', payload)
      })
    },
    GET_ROWS_SUBSCRIBER({ commit }, params) {
      return communities.getAllSubscribed(params).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        commit('SET_PAGES', pages)

        const payload = data.data
        if (!params?.page || Number(params?.page) === 1) commit('SET_ROWS', payload)
        else commit('CONCAT_ROWS', payload)
      })
    },
    SEARCH_ROWS({ commit }, searchText) {
      return search.community(searchText).then(({ data }) => {
        // const pages = {
        //   ...data.meta,
        //   totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        // }
        // commit('SET_PAGES', pages)
        commit('SET_ROWS', data.data)
      })
    },
    CHANGE_ITEM({ commit }, id) {
      return communities.get(id).then(({ data }) => {
        commit('CHANGE_ITEM', { id, payload: data.data })
      })
    },
    POST(_, payload) {
      // Preparing basic data
      const formData = new FormData()
      if (payload?.action === 'update') formData.append('_method', 'PUT')
      formData.append('title', payload.title)
      formData.append('description', payload.description)
      if (payload?.image?.file) formData.append('image', payload.image.file)

      // Preparing tags
      if (payload?.tags?.length) {
        for (let i = 0; i < payload.tags.length; i++) {
          const tag = payload.tags[i]
          formData.append('tags[]', tag.name)
        }
      }

      if (payload?.action === 'update') {
        return communities.update(formData, payload.id)
      } else {
        return communities.create(formData)
      }
    },
    DELETE({ commit }, id) {
      return communities.delete(id).then(() => {
        commit('REMOVE_ITEM', id)
      })
    }
  },
  getters: {
    rows: (s) => s.rows,
    pages: (s) => s.pages
  }
}
