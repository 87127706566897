<template>
  <form class="comments__form grid gtc-auto-1 ggap-10" @submit.prevent="sendAction">
    <a :href="profileLink + profile.id" class="comments__item-ava cover ava-30">
      <img :src="profile.avatar" />
    </a>
    <div class="grid gtc-1-auto ggap-5">
      <FormEditor
        label=""
        placeholder="Комментарий..."
        v-model="formData.body"
        required
        :disabled="isLoad"
        class="sm"
        @enterAction="sendAction"
      />
      <button
        class="btn grey pl-10 pr-10 sm"
        type="submit"
        :disabled="isLoad"
        v-tippy="'Нажмите Ctrl + Enter чтобы отправить'"
      >
        <BaseLoad v-if="isLoad" class="pos-r sm" />
        <BaseIcon v-else class="ic-16 primary" icon="send-solid" />
      </button>
    </div>
  </form>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, computed, reactive, ref, inject } from 'vue'
import { BaseIcon, BaseLoad, FormEditor } from '@/components'
import comments from '@/api/modules/comments'

// Emits
const emits = defineEmits(['updatePost'])

// Props
const props = defineProps({
  postId: {
    type: Number,
    default: 0
  },
  recipientId: {
    type: Number,
    default: 0
  },
  parentId: {
    type: Number,
    default: 0
  }
})

// Data
const store = useStore()
const formData = reactive({
  recipient_id: props.recipientId,
  parent_id: props.parentId,
  commentable_id: props.postId,
  commentable_type: 'App\\Models\\Post',
  body: ''
})
const isLoad = ref(false)
const profileLink = inject('profileLink')

// Computed
const profile = computed(() => store.getters.profile)

// Methods
function sendAction() {
  if (!formData?.body) return
  isLoad.value = true
  const cleanedMessage = formData?.body.split('<p>&nbsp;</p>').join('')

  if (cleanedMessage.trim()) {
    const payload = new FormData()
    if (formData.recipient_id) payload.append('recipient_id', formData.recipient_id)
    if (formData.parent_id) payload.append('parent_id', formData.parent_id)
    payload.append('commentable_id', formData.commentable_id)
    payload.append('commentable_type', formData.commentable_type)
    payload.append('body', cleanedMessage)

    comments
      .create(payload)
      .then(() => {
        emits('updatePost')
        formData.body = ''
      })
      .finally(() => {
        isLoad.value = false
      })
  }
}
</script>

<style lang="scss">
.comments__form {
  &.sticky {
    position: sticky;
    bottom: 0;
    background: var(--box);
    padding: 10px;
    border-radius: 0 0 var(--br) var(--br);
    margin: 0 -10px -10px -10px;
  }
}
</style>
