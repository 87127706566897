<template>
  <Draggable
    class="base-grid flex fw"
    v-model.value="mediaList"
    item-key="id"
    ghost-class="ghost"
    handle=".handle"
    @change="changedAction"
  >
    <template #item="{ element }">
      <div
        v-if="element"
        class="base-grid__item cover list video pos-r z0"
        :class="{ w50: element.type === 'video', smallHeight: mediaList && mediaList.length > 3, handle: isEdit }"
        @click.prevent="openMedia(element)"
      >
        <button
          v-if="isEdit"
          class="base-grid__delete flex ai-c jc-c"
          type="button"
          @click.prevent.stop="deleteAction(element.id)"
        >
          <BaseIcon class="ic-18 white" icon="close" />
        </button>

        <!-- Image -->
        <template v-if="element.type === 'image'">
          <img :src="element.url" style="object-fit: cover" loading="lazy" />
        </template>

        <!-- Video -->
        <template v-else>
          <div class="base-grid__play">
            <BaseIcon class="white" icon="play" />
          </div>
          <small class="base-grid__marker">{{ element.info && element.info.time ? element.info.time : '00:00' }}</small>
          <img
            :src="
              element.info && element.info.thumbnails
                ? getThumbnails(element.info.thumbnails)
                : require('@/assets/img/ava.svg')
            "
            loading="lazy"
          />
        </template>
      </div>
    </template>
  </Draggable>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'
import { defineEmits, defineProps, ref, watchEffect } from 'vue'
import { BaseIcon } from '@/components'
import { $busEmit } from '@/plugins'

// Emits
const emits = defineEmits(['mediaUpdated'])

// Props
const props = defineProps({
  media: {
    type: Array,
    default: () => []
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

// Data
const mediaList = ref([])

// Watch
watchEffect(() => (mediaList.value = cloneDeep(props.media)))

// Methods
function changedAction() {
  emits('mediaUpdated', mediaList.value)
}
function deleteAction(id) {
  const index = mediaList.value.findIndex((el) => el.id === id)
  if (index !== -1) {
    mediaList.value.splice(index, 1)
    emits('mediaUpdated', mediaList.value)
  }
}
function getThumbnails(obj) {
  let res = ''
  if (obj?.maxres) res = obj.maxres
  else if (obj?.high) res = obj.high
  else if (obj?.standard) res = obj.standard
  return res
}
function openMedia(selected) {
  $busEmit('openMedia', { selected: selected.id, arr: mediaList.value })
}
</script>

<style lang="scss" scoped>
.base-grid {
  grid-gap: 2px;
  $parent: &;

  &__marker {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    color: #000;
    z-index: 1;
    padding: 4px 6px;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }

  &__delete {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 6px;
    backdrop-filter: blur(3px);

    &:hover {
      background-color: var(--red);
    }
  }

  &__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);
    width: 40px;
    height: 40px;
    border-radius: 100px;
    padding: 5px;

    svg {
      transform: translateX(1px);
      width: 100%;
      height: 100%;
    }
  }

  &__item {
    &.ghost {
      background-color: var(--grey) !important;

      #{$parent}__play,
      #{$parent}__marker,
      #{$parent}__delete,
      img {
        opacity: 0;
      }
    }
  }
}
</style>
