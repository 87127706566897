import axios from 'axios'
import { $busEmit } from '@/plugins'

// Get token
let token = ''
const storage = window.localStorage
const getParams = new URLSearchParams(window.location.search)
if (getParams.has('data')) {
  const decode = atob(getParams.get('data'))
  token = typeof JSON.parse(decode) === 'string' ? JSON.parse(JSON.parse(decode)) : JSON.parse(decode)
} else {
  const decode = storage.getItem('choco_token')
  token = typeof JSON.parse(decode) === 'string' ? JSON.parse(JSON.parse(decode)) : JSON.parse(decode)
}

const url = window?.location?.host
const host =
  url.includes('localhost') || url.includes('test')
    ? 'https://testintra2.chocofamily.kz'
    : 'https://intra2.chocofamily.kz'

// Set configs
const client = axios.create({
  baseURL: host,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Bearer ${token?.access_token}`
  }
})

// Request
client.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
)

// Response
client.interceptors.response.use(
  (response) => {
    const data = response.data
    return data.error_code ? Promise.reject(data) : response
  },
  (error) => {
    if (error?.response?.status === 403) {
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: 'Ошибка доступа',
        message: 'У вас не достаточно прав, обратитесь к Администратору.'
      })
    } else if (error?.response?.status === 401) {
      const data = { from: window.location.href.replace(window.location.search, ''), token: null }
      const encodedData = btoa(JSON.stringify(data))
      window.location.replace(process.env.VUE_APP_INTRA + '?data=' + encodedData)
    } else if (error?.response?.status === 413) {
      $busEmit('setToast', {
        type: 'red',
        icon: 'alert-triangle',
        title: 'Ошибка 413',
        message: 'Общий размер файлов не должен быть более 50мб.'
      })
    }
    return Promise.reject(error)
  }
)

export default client
