import posts from '@/api/modules/posts'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    pages: null
  }),
  mutations: {
    CLEAR(state) {
      state.rows = []
      state.pages = null
    },
    SET_ROWS(state, payload) {
      const res = []

      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        res.push({
          ...item,
          comments: item?.comments
            ? [
                {
                  ...item?.comments,
                  child: item?.comments?.child ? [{ ...item?.comments?.child }] : []
                }
              ]
            : []
        })
      }

      state.rows = res
    },
    CONCAT_ROWS(state, payload) {
      const res = []

      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        res.push({
          ...item,
          comments: item?.comments
            ? [
                {
                  ...item?.comments,
                  child: item?.comments?.child ? [{ ...item?.comments?.child }] : []
                }
              ]
            : []
        })
      }

      state.rows = [...state.rows, ...res]
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    SET_ITEM(state, { id, payload }) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) {
        const item = {
          ...payload,
          comments: payload?.comments
            ? [
                {
                  ...payload?.comments,
                  child: payload?.comments?.child ? [{ ...payload?.comments?.child }] : []
                }
              ]
            : []
        }
        state.rows.splice(index, 1, item)
      } else {
        state.rows.unshift(payload)
      }
      if (state.rows[index] && state.rows[index].liked_user) {
        state.rows[index].liked_user = payload?.liked_user
      }
    },
    REMOVE_ITEM(state, id) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1)
    },
    CLOSE_ALL_COMMENTS(state, { postId, commentId }) {
      const index = state.rows.findIndex((el) => el.id === postId)
      if (index !== -1) {
        // Update comments
        const postComments = state.rows[index].comments
        const updatedComments = postComments.map((el) => {
          return {
            ...el,
            reply: el.id === commentId ? el?.reply : false
          }
        })

        // Update post item
        const item = { ...state.rows[index], comments: updatedComments }

        if (index !== -1) state.rows.splice(index, 1, item)
      }
    }
  },
  actions: {
    GET_ROWS({ commit }, params) {
      return posts.getAll(params).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        commit('SET_PAGES', pages)

        const payload = data.data
        if (!params?.page || Number(params?.page) === 1) commit('SET_ROWS', payload)
        else commit('CONCAT_ROWS', payload)
      })
    },
    GET_ITEM({ commit }, id) {
      return posts.get(id).then((data) => {
        commit('SET_ITEM', { id: data?.data?.data?.id, payload: data?.data?.data })
      })
    },
    POST(_, payload) {
      // Preparing basic data
      const formData = new FormData()
      if (payload?.action === 'update') formData.append('_method', 'PUT')
      if (payload.obj_type.includes('App\\Models')) {
        formData.append('obj_type', payload.obj_type)
      } else {
        formData.append('obj_type', `App\\Models\\${payload.obj_type}`)
      }
      formData.append('body', payload.body)
      formData.append('obj_id', payload.obj_id)

      // Preparing tags
      if (payload?.tags?.length) {
        for (let i = 0; i < payload.tags.length; i++) {
          const tag = payload.tags[i]
          formData.append('tags[]', tag.name)
        }
      }

      // Preparing documents
      if (payload?.documents?.length) {
        for (let i = 0; i < payload.documents.length; i++) {
          const doc = payload.documents[i]
          if (doc.file) formData.append('new_documents[]', doc.file)
        }
      }

      // Preparing links
      if (payload?.links?.length) {
        for (let i = 0; i < payload.links.length; i++) {
          const link = payload.links[i]
          formData.append(`links[${i}][id]`, link.id)
          if (!link?.file) formData.append(`links[${i}][url]`, link.url)
          if (link?.file) formData.append(`links[${i}][file]`, link.file)
          formData.append(`links[${i}][type]`, link.type)

          if (link.type === 'video') {
            for (const key in link.info) {
              if (Object.hasOwnProperty.call(link.info, key)) {
                const value = link.info[key]
                if (key !== 'thumbnails') {
                  formData.append(`links[${i}][info][${key}]`, value)
                } else {
                  for (const thumbKey in link.info.thumbnails) {
                    if (Object.hasOwnProperty.call(link.info.thumbnails, thumbKey)) {
                      const thumbValue = link.info.thumbnails[thumbKey]
                      formData.append(`links[${i}][info][thumbnails][${thumbKey}]`, thumbValue)
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (payload?.action === 'update') {
        return posts.update(formData, payload.id)
      } else {
        return posts.create(formData)
      }
    },
    DELETE({ commit }, id) {
      return posts.delete(id).then(() => {
        commit('REMOVE_ITEM', id)
      })
    }
  },
  getters: {
    // filters: (s) => s.filters,
    rows: (s) => s.rows,
    pages: (s) => s.pages
  }
}
