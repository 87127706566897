<template>
  <div class="form-fake-switch" :class="{ active: isTrue }">&nbsp;</div>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

// Props
const props = defineProps({
  isTrue: {
    type: Boolean,
    default: false
  }
})

// Data
const { isTrue } = toRefs(props)
</script>

<style lang="scss" scoped>
.form-fake-switch {
  display: block;
  background-color: var(--form-ed);
  border: var(--b1);
  width: 34px;
  height: 20px;
  border-radius: 100px;
  position: relative;
  transition: 0.3s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 3px;
    top: 3px;
    bottom: 2px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    // box-shadow: 0 0 0 1px var(--bcolor);
    border-radius: 100px;
    transition: 0.3s;
  }

  &.active {
    background-color: var(--primary);
    transition: 0.3s;
  }
  &.active::before {
    left: 17px;
    // box-shadow: none;
    transition: 0.3s;
  }
}
</style>
