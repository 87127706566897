<template>
  <div class="toast__item box pl-20 pr-20 pt-15 pb-15 grid gtc-auto-1 ggap-10" :class="item.type">
    <!-- Close -->
    <button class="toast__item-close btn xsm rounded cube-24 p-0" @click.stop="removeItem">
      <BaseIcon class="ic-14 black" icon="close" />
    </button>

    <!-- Content -->
    <div class="toast__item-icon">
      <BaseIcon class="ic-20" :icon="item.icon" />
    </div>
    <div class="toast__item-text pr-20">
      <h3 v-if="item.title" class="title mb-5">{{ item.title }}</h3>
      <div v-html="DOMPurify.sanitize(item.message)" />
    </div>
  </div>
</template>

<script setup>
import { debounce } from 'lodash'
import { defineEmits, defineProps, toRefs } from 'vue'
import { BaseIcon } from '@/components'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['remove'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({
      type: 'blue', // blue | yellow | red | green
      icon: 'alert', // bell | alert | alert-triangle | check-circle
      title: '',
      message: 'Some alert message, will be here!'
    })
  }
})

// Data
const { item } = toRefs(props)

// Created
const removeItemAfet = debounce(removeItem, 10000)
removeItemAfet()

// Methods
function removeItem() {
  emits('remove', item.value)
}
</script>

<style lang="scss" scoped>
.toast__item {
  background-color: var(--box);
  box-shadow: var(--box-shadow);
  position: relative;
  overflow: hidden;
  width: 350px;
  $item: &;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 5px;
    background-color: var(--primary);
  }

  &-icon > svg {
    fill: var(--primary);
  }

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }

  &.yellow {
    &::before {
      background-color: var(--yellow);
    }

    #{$item}-icon > svg {
      fill: var(--yellow);
    }
  }

  &.red {
    &::before {
      background-color: var(--red);
    }

    #{$item}-icon > svg {
      fill: var(--red);
    }
  }

  &.green {
    &::before {
      background-color: var(--green);
    }

    #{$item}-icon > svg {
      fill: var(--green);
    }
  }
}
</style>
