<template>
  <teleport to="body">
    <div class="modal" @scroll="scrollAction">
      <div :id="'modal__content-' + uid" class="modal__content flex fd-c">
        <slot />
      </div>

      <div class="modal__close" :style="closeHeight ? { height: closeHeight + 'px' } : ''" @click.stop="closeAction" />
    </div>
  </teleport>
</template>

<script setup>
import { defineEmits, ref, onMounted, getCurrentInstance, computed } from 'vue'
import { $busEmit } from '@/plugins'

// Emits
const emits = defineEmits(['close'])

// Data
const closeHeight = ref(0)
const uid = getCurrentInstance().uid
const el = computed(() => document.querySelector('#modal__content-' + uid))

// Methods
function closeAction() {
  emits('close')
}
function scrollAction() {
  $busEmit('scrollAction')
  if (el.value?.scrollHeight >= closeHeight.value) setHeight()
}
function setHeight() {
  closeHeight.value = el.value?.scrollHeight ? el.value.scrollHeight + 100 : 0
}

// Mounted
onMounted(() => {
  if (el?.value) el.value?.addEventListener('DOMSubtreeModified', setHeight, false)
})
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  overflow: auto;
  padding: 0;
  display: grid;
  z-index: 1000;
  backdrop-filter: blur(5px);

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 20px;
    margin: auto;
  }

  &__content {
    height: max-content;
    background-color: var(--box);
    border-radius: var(--br);
    width: fit-content;
    margin: 30px auto;
  }

  &__close {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }
}
</style>
