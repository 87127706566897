import { cutFullName } from '@/plugins'
import { posts, users, communities, search, locations, meetingRooms } from '@/api'

export default {
  namespaced: true,
  state: () => ({
    postsTags: [],
    communityTags: [],
    meetingRoomsTags: [],
    locations: [],
    users: [],
    communities: [],
    adminList: [],
    userEvents: [],
    globalUsers: [],
    userEventsLoad: true,
    eventsPage: 1,
    eventsPageCount: 1
  }),
  mutations: {
    SET_POSTS_TAGS(state, payload) {
      state.postsTags = payload || []
    },
    SET_COMMUNITY_TAGS(state, payload) {
      state.communityTags = payload || []
    },
    SET_MEETING_ROOMS_TAGS(state, payload) {
      state.meetingRoomsTags = payload || []
    },
    SET_LOCATIONS(state, payload) {
      const res = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        res.push({
          value: item.id,
          text: item.name
        })
      }
      state.locations = res
    },
    PUSH_TAG_ITEM(state, payload) {
      state[payload.name || 'tags'].push(payload.newItem)
    },
    PUSH_USER(state, payload) {
      const item = {
        id: payload.id,
        name: cutFullName(payload?.fullname),
        avatar: payload.avatar,
        type: 'user',
        specialization: payload.specialization,
        company: payload.company,
        status: payload.status
      }
      state.users.push(item)
    },
    PUSH_COMMUNITY(state, payload) {
      const item = {
        id: payload.id,
        name: payload.title,
        avatar: payload.image,
        type: 'community',
        tags: payload.tags.filter((el, index) => index < 3),
        count: payload.users?.length || 0
      }
      state.communities.push(item)
    },
    SET_USERS(state, payload) {
      const res = []
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i]
        res.push({
          id: element.id,
          name: element?.fullname,
          avatar: element.avatar,
          type: 'user',
          specialization: element.specialization,
          company: element.company,
          status: element.status
        })
      }
      state.users = res
    },
    GLOBAL_SET_USERS(state, payload) {
      const res = []
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i]
        res.push({
          id: element.id,
          name: element?.fullname,
          avatar: element.avatar,
          type: 'user',
          specialization: element.specialization,
          company: element.company,
          status: element.status
        })
      }
      state.globalUsers = res
    },
    SET_COMMUNITIES(state, payload) {
      const res = []
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i]
        res.push({
          id: element.id,
          name: element.title,
          avatar: element.image,
          type: 'community',
          tags: element.tags.filter((el, index) => index < 3),
          count: element.users?.length || 0
        })
      }
      state.communities = res
    },
    SET_ADMIN(state, payload) {
      state.adminList = payload
    },
    SET_USER_EVENTS(state, payload) {
      state.userEvents = payload
      state.userEventsLoad = false
    },
    SET_MORE_USER_EVENTS(state, payload) {
      const sorted = payload.sort((a, b) => new Date(a.date_start).getTime() - new Date(b.date_start).getTime())
      state.userEvents = [...state.userEvents, ...sorted]
    },
    SET_EVENTS_PAGE(state, { current_page, last_page }) {
      state.eventsPage = current_page || 1
      state.eventsPageCount = last_page || 1
    }
  },
  actions: {
    GET_COMMUNITY_TAGS({ commit }) {
      return communities.getCommunityTags().then(({ data }) => {
        commit('SET_COMMUNITY_TAGS', data.data)
      })
    },
    GET_POSTS_TAGS({ commit }) {
      return posts.getPostsTags().then(({ data }) => {
        commit('SET_POSTS_TAGS', data.data)
      })
    },
    GET_MEETING_ROOMS_TAGS({ commit }) {
      return meetingRooms.getTags().then(({ data }) => {
        commit('SET_MEETING_ROOMS_TAGS', data.data)
      })
    },
    GET_USER({ commit }, id) {
      return users.get(id).then(({ data }) => {
        commit('PUSH_USER', data.data)
      })
    },
    GET_COMMUNITY({ commit }, id) {
      return communities.get(id).then(({ data }) => {
        commit('PUSH_COMMUNITY', data.data)
      })
    },
    GET_LOCATIONS({ commit }) {
      return locations.getAll().then(({ data }) => {
        commit('SET_LOCATIONS', data.data)
      })
    },
    SEARCH_USERS({ commit }, searchText) {
      return search.user(searchText).then(({ data }) => {
        commit('SET_USERS', data.data)
      })
    },
    GLOBAL_SEARCH_USERS({ commit }, searchText) {
      if (searchText.length > 2) {
        return search.user(searchText).then(({ data }) => {
          commit('GLOBAL_SET_USERS', data.data)
        })
      } else {
        commit('GLOBAL_SET_USERS', [])
      }
    },
    SEARCH_COMMUNITIES({ commit }, searchText) {
      return search.community(searchText).then(({ data }) => {
        commit('SET_COMMUNITIES', data.data)
      })
    },
    GET_USER_EVENTS({ commit }) {
      return users.getUserEvents(1, 10).then(({ data }) => {
        data.data && commit('SET_USER_EVENTS', data.data)
        data.meta && commit('SET_EVENTS_PAGE', data.meta)
      })
    },
    GET_MORE_EVENTS_ROWS({ commit, state }) {
      return users.getUserEvents(state.eventsPage + 1, 10).then(({ data }) => {
        data.data && commit('SET_MORE_USER_EVENTS', data.data)
        data.meta && commit('SET_EVENTS_PAGE', data.meta)
      })
    },
    async GET_ADMIN_GROUPS({ commit }) {
      try {
        const communitiesAdmin = await communities.getWhereAdmin()
        // const teamsAdmin = await teams.getWhereAdmin() // Вернуть когда будут команды
        const payload = []

        for (let i = 0; i < communitiesAdmin.data.data.length; i++) {
          const community = communitiesAdmin.data.data[i]
          payload.push({
            id: community.id,
            name: community.title,
            avatar: community.image || require('@/assets/img/no-photo.jpg'),
            type: 'Community'
          })
        }
        // Вернуть когда будут команды
        // for (let i = 0; i < teamsAdmin.data.data.length; i++) {
        //   const team = teamsAdmin.data.data[i]
        //   payload.push({
        //     id: team.id,
        //     name: team.name,
        //     avatar: team?.avatar || require('@/assets/img/no-photo.jpg'),
        //     type: 'Team'
        //   })
        // }

        commit('SET_ADMIN', payload)
      } catch (error) {
        console.log(error)
      }
    }
  },
  getters: {
    communityTags: (s) => s.communityTags,
    meetingRoomsTags: (s) => s.meetingRoomsTags,
    postsTags: (s) => s.postsTags,
    users: (s) => s.users,
    globalUsers: (s) => s.globalUsers,
    communities: (s) => s.communities,
    locations: (s) => s.locations,
    adminList: (s) => s.adminList,
    userEvents: (s) => s.userEvents,
    userEventsLoad: (s) => s.userEventsLoad,
    getEventsPage: (s) => s.eventsPage,
    getEventsPageCount: (s) => s.eventsPageCount
  }
}
