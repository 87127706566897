export default [
  {
    path: 'meeting-rooms',
    component: () => import(/* webpackChunkName: "views-meeting-rooms" */ '@/views/meeting-rooms/index.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Переговорные'
        },
        component: () => import(/* webpackChunkName: "views-meeting-rooms" */ '@/views/meeting-rooms/list.vue'),
        children: [
          {
            path: 'single/:id',
            meta: {
              title: 'Переговорная'
            },
            component: () =>
              import(/* webpackChunkName: "views-meeting-rooms-single" */ '@/views/meeting-rooms/single.vue')
          }
        ]
      }
    ]
  },
  {
    path: 'meeting-rooms/single/:id',
    component: () => import(/* webpackChunkName: "views-meeting-rooms" */ '@/views/meeting-rooms/single.vue')
  }
]
