import axios from '../axios'

class UsersApi {
  get(id) {
    return axios.get('/api/users/' + id)
  }
  getAll(searchText = '') {
    const ser = searchText ? searchText : 'a'
    return axios.get('/api/search?fullname=' + ser)
  }
  getUserEvents(page, size) {
    return axios.get(`/api/negotiation/index/event?page=${page}&size=${size}`)
  }
}

export default new UsersApi()
