<template>
  <div class="base-pagination" :class="{ 'flex ai-c jc-sb ggap-20': isSize, disabled: isDisabled }">
    <!-- Size -->
    <div v-if="isSize" class="base-pagination__size flex ai-c ggap-10">
      <FormSelect
        label=""
        placeholder=""
        class="sm white"
        :options="optionsPageSize"
        required
        v-model="currentPageSize"
      />
      на странице
    </div>

    <!-- Pages -->
    <div class="base-pagination__pages flex ai-c jc-sb ggap-10">
      <button
        class="btn sm outline pl-10 pr-10"
        :disabled="propValue === 1"
        @click.prevent="propValue > 1 ? (propValue = propValue - 1) : propValue"
      >
        <BaseIcon class="ic-16 primary" icon="arrow-left" />
        Назад
      </button>

      <div v-if="pages" class="flex ai-c ggap-5">
        <button class="btn sm" :class="propValue === 1 ? 'primary' : 'outline'" @click.prevent="propValue = 1">
          1
        </button>

        <span v-if="propValue > 3" class="base-pagination__pages-empty flex ai-c jc-c">
          <BaseIcon class="ic-16 grey" icon="more-horizontal" />
        </span>

        <div class="flex ai-c ggap-5">
          <template v-for="page of totalPages" :key="page.number">
            <button
              v-if="page.number > 1 && page.number !== pages.last_page"
              class="btn sm"
              :class="page.number === propValue ? 'primary' : 'outline'"
              @click.prevent="page.type !== 'empty' ? (propValue = page.number) : ''"
            >
              {{ page.number }}
            </button>
            <!-- <button
              v-else-if="totalPages.length > 3 && page.number !== pages.last_page"
              class="btn sm"
              :class="page.number === propValue ? 'primary' : 'outline'"
              @click.prevent="page.type !== 'empty' ? (propValue = page.number) : ''"
            >
              {{ page.number }}
            </button> -->
          </template>
        </div>

        <span v-if="propValue + 3 < pages.last_page" class="base-pagination__pages-empty flex ai-c jc-c">
          <BaseIcon class="ic-16 grey" icon="more-horizontal" />
        </span>

        <button
          v-if="pages.last_page > 1"
          class="btn sm"
          :class="propValue === pages.last_page ? 'primary' : 'outline'"
          @click.prevent="propValue = pages.last_page"
        >
          {{ pages.last_page }}
        </button>
      </div>

      <button
        class="btn sm outline pl-10 pr-10"
        :disabled="propValue === pages.last_page"
        @click.prevent="propValue < pages.last_page ? (propValue = propValue + 1) : propValue"
      >
        Вперед
        <BaseIcon class="ic-16 primary" icon="arrow-right" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, toRefs, computed } from 'vue'
import { BaseIcon, FormSelect } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  isSize: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  pages: {
    type: Object,
    default: () => ({})
  },
  modelValue: {
    type: Number,
    default: 1
  }
})

// Data
const { isSize, isDisabled, modelValue, pages } = toRefs(props)
const currentPageSize = ref(10)
const optionsPageSize = [
  {
    text: '10',
    value: 10
  },
  {
    text: '20',
    value: 50
  },
  {
    text: '50',
    value: 50
  },
  {
    text: 100,
    value: 100
  }
]

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const totalPages = computed(() => {
  const res = []
  for (let i = 0; i < pages.value.totalPages; i++) {
    const page = i + 1
    res.push({
      type: 'page',
      number: page
    })
  }

  const start = res.slice(propValue.value - 3, propValue.value - 1)
  const end = res.slice(propValue.value - 1, propValue.value + 2)

  return res.length < 3 ? res : [...start, ...end]
})
</script>

<style lang="scss" scoped>
.base-pagination {
  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
}
</style>
