import { nextTick } from 'vue'

function countHidden(element) {
  const elementRect = element.getBoundingClientRect()
  const elementHides = {
    top: Math.max(0, 0 - elementRect.top),
    left: Math.max(0, 0 - elementRect.left),
    bottom: Math.max(0, elementRect.bottom - window.innerHeight),
    right: Math.max(0, elementRect.right - window.innerWidth)
  }
  return elementHides
}

export async function calcCoords(uniqId, direction, isSetFocus = false) {
  // Ширина высота вьюпорта
  // const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  // Получаем координаты кнопки
  const btnElement = document.querySelector(`[data-btn="${uniqId}"]`)
  const coordsBtn = btnElement.getBoundingClientRect()

  // Ждем пока откроется тело
  await nextTick()

  // Если надо то ставим фокус
  if (isSetFocus) {
    const el = document.getElementById(uniqId)
    if (el) el.focus()
  }

  // Вставляем полученые координаты кнопки в тело
  const bodyElement = document.querySelector(`[data-body="${uniqId}"]`)
  if (bodyElement) {
    const coordsBody = bodyElement.getBoundingClientRect()
    bodyElement.style.top =
      vh / 2 > coordsBtn.bottom ? coordsBtn.bottom + 2 + 'px' : coordsBtn.top - coordsBody.height - 2 + 'px'

    if (direction === 'rtl') {
      bodyElement.style.left = coordsBtn.right - coordsBody.width + 'px'
    } else if (direction === 'ltr') {
      bodyElement.style.left = coordsBtn.left + 'px'
    } else {
      bodyElement.style.width = coordsBtn.width + 'px'
      bodyElement.style.left = coordsBtn.left + 'px'
    }

    // Если выходит на пределы экрана
    const countPos = countHidden(bodyElement)
    if (countPos.top > 0) bodyElement.style.top = '5px'
  }
}
