<template>
  <div
    class="form-item grid"
    :class="{ required: required, disabled: disabled, error: isError }"
    :data-success="isSuccess"
  >
    <div v-if="label" class="flex ai-c jc-sb">
      <label class="form-item__label">
        {{ label }}
      </label>
      <small v-if="maxlength" class="fs-12"
        ><b :class="{ 't-red': propValue.length > maxlength }">{{ propValue.length }}</b
        >/{{ maxlength }}</small
      >
    </div>
    <input
      v-if="maxlength || minlength"
      :type="type"
      :name="'form-item-' + uid"
      class="form-item__input pl-10 pr-10"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :minlength="minlength"
      :maxlength="maxlength"
      v-model="propValue"
      min="10"
    />
    <input
      v-else
      :type="type"
      :name="'form-item-' + uid"
      class="form-item__input pl-10 pr-10"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      v-model="propValue"
    />
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, computed } from 'vue'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  minlength: {
    type: Number,
    default: 0
  },
  maxlength: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [String, Number],
    default: ''
  }
})

// Data
const { label, type, placeholder, required, minlength, maxlength, modelValue } = toRefs(props)
const uid = getCurrentInstance().uid

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => {
    let str = val.trim()
    str = DOMPurify.sanitize(str)
    emits('update:modelValue', str)
  }
})
const isSuccess = computed(() => {
  let res = false
  if (propValue.value) {
    res =
      maxlength.value || minlength.value
        ? propValue.value.length <= maxlength.value && propValue.value.length >= minlength.value
        : !!propValue.value
  }
  return res
})
</script>

<style lang="scss" scoped></style>
