<template>
  <header class="head grid b-b">
    <div class="content flex ai-c ggap-20">
      <div class="head__left flex ai-c jc-sb ggap-10">
        <a href="https://intra.chocofamily.kz" class="head__logo pt-10 pb-10 flex ai-c">
          <img src="@/assets/img/logo-white.svg" class="white" v-if="!isMobile" />
          <img src="@/assets/img/logo-black.svg" class="black" v-if="!isMobile" />
          <img src="@/assets/img/heart.svg" class="heart" v-if="isMobile" />
        </a>

        <div class="select-app">
          <BaseDropDown btn-class="btn grey pl-10 pr-10 fw-b">
            <template #btn>
              <BaseIcon class="ic-18 black" icon="apps" />
            </template>
            <template #body>
              <div class="grid p-5" v-if="!isMobile">
                <a href="https://intra.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-16 primary" icon="home" />
                  Интра
                </a>
                <a href="https://soc.chocofamily.kz" class="btn primary md fw-b br-5" @click.prevent>
                  <BaseIcon class="ic-16 white" icon="users" />
                  Социальная сеть
                </a>
                <a href="https://law.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-16 primary" icon="doc-1" />
                  Юристы
                </a>
                <a href="https://payments.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-20 primary" icon="kzt" />
                  Финансисты
                </a>
                <a href="https://sysadmin.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-20 primary" icon="help" />
                  Сисадмины
                </a>
                <hr v-if="isMobile" />
              </div>
              <div v-if="isMobile">
                <RouterLink to="/news" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
                  <BaseIcon class="ic-20 grey" icon="flash" />
                  Новости
                </RouterLink>
                <RouterLink to="/communities" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
                  <BaseIcon class="ic-20 grey" icon="users" />
                  Сообщества
                </RouterLink>
                <RouterLink to="/events" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
                  <BaseIcon class="ic-20 grey" icon="trophy" />
                  Мероприятия
                </RouterLink>
                <RouterLink to="/meeting-rooms" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
                  <BaseIcon class="ic-20 grey" icon="work" />
                  Переговорные
                </RouterLink>
                <RouterLink to="/meets" class="btn transparent-grey-outline ggap-15 lg" active-class="active">
                  <BaseIcon class="ic-20 grey" icon="calendar" />
                  События
                </RouterLink>
              </div>
            </template>
          </BaseDropDown>
        </div>
      </div>

      <Search v-if="!isMobile" />

      <div class="head__right ml-auto flex ai-c ggap-10">
        <nav class="head__nav flex ai-c ggap-10">
          <img class="chocofest" :src="require('@/assets/img/chocofest.png')" />
        </nav>
        <BaseDropDown ref="ddownadd" btn-class="btn transparent-grey pl-10 pr-10" v-if="isAdmin" direction="rtl">
          <template #btn>
            <BaseIcon class="ic-18 grey" icon="plus-circle" />
          </template>
          <template #body>
            <div class="p-5 grid">
              <button class="btn transparent-grey" @click="addAction('post/Index.vue')">
                <BaseIcon class="ic-18 grey" icon="flash" />
                Добавить пост
              </button>
              <button class="btn transparent-grey" @click="addAction('community/Index.vue')">
                <BaseIcon class="ic-18 grey" icon="users" />
                Создать сообщество
              </button>

              <!-- Это только для админов -->
              <template v-if="isAdmin">
                <hr />
                <button class="btn transparent-grey" @click="addAction('meeting-rooms/Index.vue')">
                  <BaseIcon class="ic-18 grey" icon="work" />
                  Добавить переговорную
                </button>
                <button class="btn transparent-grey" @click="addAction('event/Index.vue')">
                  <BaseIcon class="ic-18 grey" icon="trophy" />
                  Добавить мероприятие
                </button>
              </template>
            </div>
          </template>
        </BaseDropDown>

        <!-- <Notifications /> -->

        <SwitchTheme />

        <User />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { BaseIcon, BaseDropDown } from '@/components'
import Search from './Search.vue'
// import Notifications from './notifications/Index.vue'
import SwitchTheme from './SwitchTheme.vue'
import User from './User.vue'
import { $busEmit } from '@/plugins'

// Data
const ddownadd = ref()
const store = useStore()

// Computed
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const isMobile = computed(() => store.getters.isMobile)

// Methods
function addAction(url) {
  $busEmit('add', url)
  ddownadd.value.closeAction()
}
</script>

<style lang="scss">
.chocofest {
  height: 40px;
  border-radius: 10px;
}
.head {
  background-color: var(--head);
  height: 50px;

  &__left {
    width: 180px;
  }

  &__logo {
    img {
      width: auto;
      height: 18px;

      &.white {
        display: none;
      }
    }
  }

  &__nav {
    &-item {
      text-decoration: none;
      color: #fff;
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
  }
}

.dark {
  .head__logo {
    img {
      &.white {
        display: flex;
      }
      &.black {
        display: none;
      }
    }
  }
}
</style>
