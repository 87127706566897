import axios from '../axios'

class CommentsApi {
  getAll(postId) {
    return axios.get(`/api/comments/index/${postId}/post`)
  }
  get(id) {
    return axios.get(`/api/comments/${id}`)
  }
  getCount(postId) {
    return axios.get(`/api/comments/count/${postId}/post`)
  }
  create(payload) {
    return axios.post('/api/comments', payload)
  }
  like(id) {
    const formData = new FormData()
    formData.append('comment_id', id)
    return axios.post('/api/comments/like', formData)
  }
  unlike(id) {
    return axios.delete(`/api/comments/like/${id}`)
  }
}

export default new CommentsApi()
