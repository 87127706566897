<template>
  <div
    class="form-file grid ggap-10"
    :class="{ required: required && !propValue[keyName]?.name, disabled: disabled, errorText: isError }"
    :data-success="isSuccess"
  >
    <!-- List -->
    <ItemFile
      :item="propValue[keyName]?.file"
      v-if="propValue[keyName]?.file"
      @click="anotherFile"
      @remove="removeItem"
    />
    <input
      type="file"
      name="file"
      :required="required"
      :id="keyName"
      class="form-file__input"
      @change="setFiles($event.target.files)"
      :accept="acceptTypes"
    />
    <label :for="keyName" class="form-file__label">
      <!-- Empty -->
      <div v-if="!propValue[keyName]?.file" class="form-file__empty flex fd-c ai-c ggap-5 p-20">
        <h4 class="title errorText">
          {{ title ? title : `Загрузите один файл (${acceptTypes.split('.').join(' ')})` }}
        </h4>
        <small v-if="title">{{ acceptTypes.split('.').join(' ') }}</small>
        <small class="t-red">{{ error }}</small>
        <div class="btn primary-light sm mt-10">
          <BaseIcon class="ic-16 primary" icon="plus-circle" />
          Выберите файлы
        </div>
      </div>
    </label>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed, ref, watch } from 'vue'
import { BaseIcon, ItemFile } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  modelValue: {
    type: Array,
    default: new Array()
  },
  acceptTypes: {
    type: String,
    default: '.svg,.jpg,.jpeg,.png,.gif,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.txt'
  },
  title: {
    type: String,
    default: ''
  },
  keyName: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  }
})

// Data
const { modelValue, acceptTypes, title, keyName, required } = toRefs(props)
const error = ref('')
const isSuccess = ref(false)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Methods
function setFiles(files) {
  const item = files[0] || {}
  const fileType = item.type.split('/')[1]
  const accept = acceptTypes.value.replaceAll('.', '').split(',')
  if (!accept.includes(fileType)) {
    error.value = 'Расширение файла не поддерживается'
  } else {
    propValue.value[keyName.value] = {
      id: item.lastModified,
      name: item.name,
      mime: item.type,
      size: item.size,
      file: item
    }
  }

  const inputFile = document.getElementById(keyName.value)
  inputFile.value = ''
}

watch(propValue, () => {
  isSuccess.value = !!propValue[keyName.value]?.file
})

function anotherFile() {
  document.getElementById(keyName.value).click()
}

function removeItem() {
  propValue.value[keyName.value] = {}
}
</script>

<style lang="scss" scoped>
.form-file {
  &__input {
    display: none;
  }

  &__list {
    border-radius: var(--br);
    border: var(--b1);
  }

  // &__empty {
  //   border: 2px var(--bcolor) dashed;
  //   border-radius: var(--br);
  // }
}
</style>
