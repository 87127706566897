<template>
  <!-- <div class="comments__item-el grid"> -->
  <div v-if="item" class="comments__item grid gtc-auto-1 ggap-10 pt-10" :class="{ 'pl-40': isChild }">
    <div v-if="isDelete" class="comments__delete pos-a z1 flex ai-c jc-c ggap-10">
      <div v-if="isLoad" class="flex ai-c ggap-5">
        <BaseLoad class="rel sm" />
        <h3 class="title t-white">Идет удаление записи!</h3>
      </div>
      <template v-else>
        <h3 class="title t-white">Вы действительно хотите удалить комментарии?</h3>
        <button class="btn primary sm" @click="deleteAction">Удалить</button>
        <button class="btn cancel sm" @click="isDelete = false">Отмена</button>
      </template>
    </div>
    <!-- Avatar -->
    <a v-if="item?.author" :href="profileLink + item?.author?.id" class="comments__item-ava cover ava-30 bordered">
      <img :src="item?.author?.avatar" />
    </a>
    <div v-if="!item.author" class="comments__item-ava cover ava-30 bordered"><img :src="getAvatar()" /></div>

    <!-- Data -->
    <div class="comments__item-data">
      <!-- Top -->
      <div class="flex ai-c jc-sb pos-r z0">
        <div class="flex ai-c ggap-10">
          <a :href="profileLink + item.author.id" v-if="item?.author?.id" class="comments__item-user">
            {{ `${item?.author?.surname} ${item?.author?.name}` || 'Анонимный пользователь' }}
          </a>
          <div class="comments__item-user" v-if="!item?.author?.id">Анонимный пользователь</div>
          <div v-if="item.recipient && !isMobile" class="comments__item-reply flex ai-c ggap-5">
            ответил(а): <a :href="profileLink + item.recipient.id">{{ item.recipient.fullname }}</a>
          </div>
        </div>
      </div>

      <!-- Message -->
      <div
        class="comments__item-message pr-40"
        v-html="DOMPurify.sanitize(item.name)"
        :class="{ wrapped: !isUnwrapped && canUnwrap }"
      />
      <small v-if="item.name && canUnwrap" class="pointer pb-10 primary-color-text" @click="isUnwrapped = !isUnwrapped">
        {{ isUnwrapped ? 'Свернуть' : 'Развернуть' }}
      </small>

      <!-- Actions -->
      <div class="comments__item-actions flex ai-c jc-sb pb-5 pt-10 pb-10">
        <div class="flex ai-c ggap-10">
          <small>{{ formatRuDateTime(item.created_at) }}</small>
          <a href="#" @click.prevent="formToggler">Ответить</a>
          <span v-if="item?.author?.id === profile?.id" class="errorText pointer" @click="isDelete = true">
            Удалить
          </span>
        </div>
      </div>

      <Form
        v-if="itemModel.reply"
        :questionId="questionId"
        :parentId="parentId"
        class="mb-10"
        @updateQuestion="emits('updateQuestion')"
      />
    </div>
  </div>

  <template v-if="item.replies && item.replies.length && item.replies[0]">
    <Item
      v-for="subitem of item.replies"
      :key="subitem.id"
      :item="subitem"
      :questionId="questionId"
      :parentId="item?.id"
      isChild
      @formOpen="emits('formOpen', subitem.id)"
      @updateQuestion="emits('updateQuestion')"
    />
  </template>
  <!-- </div> -->
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed, ref, inject } from 'vue'
import { formatRuDateTime, getAvatar } from '@/plugins'
import { BaseLoad } from '@/components'
import Item from './Item.vue'
import Form from './Form.vue'
import DOMPurify from 'dompurify'
import { useStore } from 'vuex'
import { events } from '../../api'

// Emits
const emits = defineEmits(['update:item', 'formOpen', 'updateQuestion'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  isChild: {
    type: Boolean,
    default: false
  },
  questionId: {
    type: Number,
    default: 0
  },
  parentId: {
    type: Number,
    default: 0
  }
})

// Data
const { item, questionId, parentId } = toRefs(props)
const isLiked = ref(false)
const likeCount = ref(0)
const isUnwrapped = ref(false)
const profileLink = inject('profileLink')
const store = useStore()
const isLoad = ref(false)
const isDelete = ref(false)

// Computed
const profile = computed(() => store.getters.profile)
const isMobile = computed(() => store.getters.isMobile)
const itemModel = computed({
  get: () => item.value,
  set: (val) => emits('update:item', val)
})
const canUnwrap = computed(() => {
  const el = document.createElement('div')
  el.innerHTML = DOMPurify.sanitize(item.value.body)
  return item.value?.body?.length > 480 || el.querySelectorAll('p').length > 6
})

// Created
isLiked.value = item.value.liked
likeCount.value = item.value.likes_count

// Methods
function formToggler() {
  itemModel.value.reply = itemModel.value?.reply ? !itemModel.value.reply : true
  emits('formOpen', itemModel.value.id)
}

async function deleteAction() {
  try {
    isLoad.value = true
    await events.deleteComment(item.value?.id)
    emits('updateQuestion')
  } catch (err) {
    console.log(err)
  } finally {
    isLoad.value = false
  }
}
</script>
