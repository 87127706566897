<template>
  <div
    class="form-item grid"
    :class="{ required: required, disabled: disabled, error: isError }"
    :data-success="isSuccess"
    v-click-outside="
      () => {
        isOpen = false
      }
    "
  >
    <label v-if="label" class="form-item__label">
      {{ label }}
    </label>
    <div class="form-item__input form-tags flex fw ggap-5 ai-c p-5" :class="{ focused: isFocused }">
      <div v-for="tag of propValue" :key="tag.id" class="form-tags__item box grid gtc-1-auto ai-c ggap-10 cut">
        <div class="cut">{{ tag.name }}</div>
        <button class="btn rounded cube-20" type="button" @click.prevent="removeTag(tag.id)">
          <BaseIcon class="ic-16 grey" icon="close" />
        </button>
      </div>

      <div class="form-tags__select pos-r z1" v-click-outside="() => (isOpen = false)">
        <input
          type="text"
          :placeholder="placeholder"
          class="form-tags__select-input w-100 pl-10 pr-10"
          :data-btn="uniqId"
          v-model.trim="searchText"
          @keypress.enter="newTag"
          @keyup="removeLastTag"
          @click="toggleAction"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />

        <teleport to="body">
          <div v-if="isOpen" class="form-tags__select-ddown grid p-5 box z99999" :data-body="uniqId">
            <template v-if="tagsList && tagsList.length">
              <button
                v-for="tag of tagsList"
                :key="tag.id"
                class="btn transparent-grey sm cut"
                :class="{ active: isHas(tag.id) }"
                type="button"
                @click.prevent="setTag(tag)"
              >
                {{ tag.name }}
              </button>
            </template>
            <div v-else class="p-10 grid ggap-5">
              <h4 class="title">Ничего не найдено!</h4>
              <small v-if="canAdd" class="t-grey-dark">нажмите <b>Enter</b> чтобы добавить новый тег.</small>
            </div>
          </div>
        </teleport>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, ref, toRefs, computed, nextTick, watch } from 'vue'
import { BaseIcon } from '@/components'
import { calcCoords } from '@/plugins'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  tagsName: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Выберите теги'
  },
  maxlength: {
    type: Number,
    default: -1
  },
  dispatcher: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  isError: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Array,
    default: () => []
  },
  canAdd: {
    type: Boolean,
    default: true
  }
})

// Data
const { label, placeholder, required, modelValue, tagsName, dispatcher, canAdd } = toRefs(props)
const store = useStore()
const isFocused = ref(false)
const isOpen = ref(false)
const searchText = ref('')
const uniqId = 'ddown-tags'
const counter = ref(0)

// Computed
const tags = computed(() => store.getters[`helpers/${tagsName.value}`])

const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
const tagsList = computed(() => {
  const str = searchText.value.toLocaleLowerCase()
  return searchText.value ? tags.value.filter((el) => el.name.toLocaleLowerCase().includes(str)) : tags.value
})
const isSuccess = computed(() => !!propValue.value.length)

// Watch
watch(searchText, () => {
  isOpen.value = true
  nextTick(() => calcCoords(uniqId, 'ltr'))
})

// Created
store.dispatch(`helpers/${dispatcher.value}`)

// Methods
async function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, 'ltr')
  counter.value = 0
}
function isHas(id) {
  return propValue.value.findIndex((el) => el.id === id) !== -1
}
function setTag(tag) {
  const index = propValue.value.findIndex((el) => el.id === tag.id)
  if (index !== -1) {
    propValue.value.splice(index, 1)
  } else {
    propValue.value.push(tag)
    searchText.value = ''
  }
  nextTick(() => calcCoords(uniqId, 'ltr'))
  counter.value = 0
}
function removeTag(tag) {
  const index = propValue.value.findIndex((el) => el.id === tag)
  if (index !== -1) propValue.value.splice(index, 1)
  nextTick(() => calcCoords(uniqId, 'ltr'))
  counter.value = 0
}
function removeLastTag(event) {
  var key = event.keyCode || event.charCode
  if (key == 8 && !searchText.value) {
    counter.value = counter.value + 1

    if (counter.value > 2 && propValue.value.length) {
      propValue.value.pop()
      nextTick(() => calcCoords(uniqId, 'ltr'))
      counter.value = 0
    }
  }
}
function newTag() {
  if (searchText.value && canAdd.value) {
    const name = searchText.value.toLocaleLowerCase()
    const hasIndex = tags.value.findIndex((el) => el.name === name)

    if (hasIndex !== -1) {
      setTag(tags.value[hasIndex])
    } else {
      const newItem = {
        id: new Date().getTime() / 1000,
        name
      }
      setTag(newItem)
      store.commit('helpers/PUSH_TAG_ITEM', { newItem, name: tagsName.value })
    }

    nextTick(() => calcCoords(uniqId, 'ltr'))
    counter.value = 0
  }
}
</script>

<style lang="scss" scoped>
.form-tags {
  // background-color: var(--grey-l);

  &__item {
    height: 26px;
    padding: 0px 2px 0 10px;
  }

  &__select {
    flex: auto;
    min-width: 120px;

    &-input {
      border: 0;
      background-color: transparent;
      height: 26px;

      // &::-webkit-input-placeholder {
      //   color: #909;
      // }
      // &:-moz-placeholder {
      //   color: #909;
      //   opacity: 1;
      // }
      // &::-moz-placeholder {
      //   color: #909;
      //   opacity: 1;
      // }
      // &:-ms-input-placeholder {
      //   color: #909;
      // }
      // &::-ms-input-placeholder {
      //   color: #909;
      // }
      // &::placeholder {
      //   color: var(--text);
      // }
      // &:focus {
      //   &::placeholder {
      //     opacity: 0.4;
      //   }
      // }
    }

    &-ddown {
      position: fixed;
      box-shadow: var(--box-shadow);
      grid-gap: 1px;
      max-height: 350px;
      max-width: 300px;
      overflow: auto;

      .btn {
        display: block;
        text-align: left;
      }
    }
  }
}
</style>
