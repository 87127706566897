<template>
  <div class="comments__list grid">
    <Item
      v-for="item of itemsModel"
      :key="item.id"
      :item="item"
      :postId="postId"
      :parentId="item.id"
      @formOpen="closeForm"
      @updatePost="emits('updatePost')"
    />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import Item from './Item.vue'

// Emits
const emits = defineEmits(['update:items', 'mainFormClose', 'updatePost'])

// Props
const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  postId: {
    type: Number,
    default: 0
  }
})

// Data
const { items, postId } = toRefs(props)

// Computed
const itemsModel = computed({
  get: () => items.value,
  set: (val) => emits('update:items', val)
})

// Methods
function closeForm(commentId) {
  for (let i = 0; i < itemsModel.value.length; i++) {
    const el = itemsModel.value[i]
    el.reply = el.id === commentId ? el?.reply : false

    if (el?.child?.length) {
      for (let i = 0; i < el.child.length; i++) {
        const subEl = el.child[i]
        subEl.reply = subEl.id === commentId ? subEl?.reply : false
      }
    }
  }

  emits('mainFormClose')
}
</script>

<style lang="scss">
.comments {
  &__item {
    $item: &;

    // &-el {
    //   &:last-child {
    //     border-bottom: 0;
    //     // #{$item}:last-child #{$item}-data {
    //     //   background-color: red;
    //     // }
    //   }
    // }

    &-data {
      border-bottom: var(--b1);

      // &.last {
      //   border-bottom: 0;
      // }
    }
    &:last-child &-data {
      border-bottom: 0;
    }

    &-user {
      color: var(--secondary);
    }

    &-message {
      p {
        margin: 5px 0 0;
      }
    }

    &-reply,
    &-reply > a {
      color: var(--grey-ed);
    }

    &-actions {
      small {
        color: var(--grey-ed);
      }
    }

    &-report {
      opacity: 0;
      right: 0;
      top: 0;
    }
    &:hover &-report {
      opacity: 1;
    }
    &-like {
      opacity: 0;
    }
    &:hover &-like,
    &-like.has {
      opacity: 1;
    }
  }
}
</style>
