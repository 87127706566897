import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

const token = localStorage.getItem('choco_token') ? JSON.parse(localStorage.getItem('choco_token')) : null
// const host = process.env.VUE_APP_BASEURL || 'https://testintra2.chocofamily.kz'
const url = window?.location?.host
const host =
  url.includes('localhost') || url.includes('test')
    ? 'https://testintra2.chocofamily.kz'
    : 'https://intra2.chocofamily.kz'

window.Pusher = Pusher
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '9cc3ed3e2a3ebc6b21a0',
  cluster: 'ap2',
  wsHost: 'intra2.chocofamily.kz',
  encrypted: true,
  wssPort: 443,
  disableStats: true,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  authEndpoint: host + '/broadcasting/auth',
  auth: {
    headers: {
      Authorization: `Bearer ${token?.access_token || ''}`
    }
  }
})

// Check connection
window.Echo.connector.pusher.connection.bind('disconnected', () => {
  console.log(
    '%cУтерянно соединение с сокетами',
    'background: red; color: white; padding: 2px 5px; border-radius: 5px;'
  )
})
// window.Echo.connector.pusher.connection.bind('connected', () => {
//   console.log('%cСоединились с сокетами', 'background: green; color: white; padding: 2px 5px; border-radius: 5px;')
// })

export default window.Echo
