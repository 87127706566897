<template>
  <div class="form-radio-group form-item__input flex ai-c">
    <button
      type="button"
      v-for="item of items"
      :key="item.value"
      class="form-radio-group__btn btn sm h-100 f-1 jc-c"
      :class="item.value === propValue ? 'primary' : ''"
      @click="propValue = item.value"
    >
      {{ item.text }}
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  modelValue: {
    type: [String, Number],
    default: 1
  }
})

// Data
const { items, modelValue } = toRefs(props)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})
</script>

<style lang="scss" scoped>
.form-radio-group {
  grid-gap: 0;
  padding: 0;

  &__btn {
    border-radius: 0;

    &:first-child {
      border-radius: var(--br) 0 0 var(--br);
    }
    &:last-child {
      border-radius: 0 var(--br) var(--br) 0;
    }
  }
}
</style>
