<template>
  <div v-if="item && profile" class="item-post box">
    <!-- Delete -->
    <div v-if="isDelete" class="item-post__delete flex fd-c ai-c p-30 ta-center">
      <div v-if="isLoad" class="item-post__delete-box m-auto flex fd-c ai-c ggap-20">
        <BaseLoad class="rel sm" />
        <h2 class="title t-white">Идет удаление записи!</h2>
      </div>
      <div v-else class="item-post__delete-box m-auto flex fd-c ai-c ggap-20">
        <h2 class="title t-white">Вы действительно<br />хотите удалить эту запись?</h2>

        <div class="flex ai-c ggap-10">
          <button class="btn primary" @click="deleteAction">Да, удаляй!</button>
          <button class="btn cancel" @click="isDelete = false">Нет, не хочу.</button>
        </div>
      </div>
    </div>

    <!-- Head -->
    <div class="item-post__head p-10 flex ai-c jc-sb ggap-20">
      <!-- Left -->
      <div v-if="item.owner" class="flex ai-c ggap-10">
        <!-- User -->
        <div class="item-post__user grid gtc-auto-1 ggap-10 ai-c">
          <a
            v-if="item.object_type === 'App\\Models\\User'"
            :href="profileLink + item.owner.id"
            class="cover ava-40 bordered"
          >
            <img :src="avatar" :onerror="setImage" />
          </a>
          <RouterLink v-else :to="'/communities/single/' + item.owner.id" class="cover ava-40 bordered">
            <img :src="avatar" :onerror="setImage" />
          </RouterLink>
          <div class="grid cut">
            <a v-if="item.object_type === 'App\\Models\\User'" :href="profileLink + item.owner.id" class="title cut">
              {{ cutFullName(item.owner.name) || 'Без имени' }}
            </a>
            <RouterLink v-else :to="'/communities/single/' + item.owner.id" class="title cut">
              {{ item.owner.name || 'Без имени' }}
            </RouterLink>
            <small class="mt-5">{{ formatRuDateTime(item.created_at) }}</small>
          </div>
        </div>

        <!-- Publication action -->
        <BaseLoad v-if="item.state && item.state === 'loading'" class="rel sm" v-tippy="'Идет публикация поста'" />
        <button
          v-if="item.state && item.state === 'error'"
          class="btn grey sm pl-10"
          v-tippy="'Не удалось опубликовать запись, нажмите сюда чтобы снова отправить на публикацию!'"
          @click.prevent="rePublish"
        >
          <BaseIcon class="ic-16 red" icon="alert" />
          Отправить снова
        </button>
      </div>

      <!-- Right -->
      <BaseDotMenu v-if="item.author">
        <button v-if="!isCommentsFull" class="btn transparent-grey" @click.prevent="openPost">
          <BaseIcon class="ic-16 grey" icon="eye" /> Открыть пост
        </button>
        <button class="btn transparent-grey" @click.prevent="shareAction">
          <BaseIcon class="ic-16 grey" icon="share" /> Поделиться
        </button>

        <template v-if="item.author.id === profile.id">
          <hr />
          <button class="btn transparent-grey" @click="$busEmit('edit', { url: 'post/Index.vue', id: item.id })">
            <BaseIcon class="ic-16 grey" icon="edit" /> Редактировать
          </button>
          <button class="btn transparent-grey" @click="isDelete = !isDelete">
            <BaseIcon class="ic-16 grey" icon="delete" /> Удалить
          </button>
        </template>
      </BaseDotMenu>
    </div>

    <!-- Body -->
    <div class="item-post__body grid ggap-5 p-10">
      <!-- Tags -->
      <div v-if="item.tags && item.tags.length" class="item-post__body-tags flex fw ggap-10">
        <span v-for="tag of item.tags" :key="tag.id" class="tag-item">{{ tag.name }}</span>
      </div>

      <!-- Text -->
      <div v-if="item.body" class="item-post__body-text w" @click.prevent="openPost" />

      <div :class="{ wrapped: !isUnwrapped && canUnwrap }" v-html="DOMPurify.sanitize(item.body)"></div>
      <small v-if="item.body && canUnwrap" class="pointer pb-10 primary-color-text" @click="isUnwrapped = !isUnwrapped">
        {{ isUnwrapped ? 'Свернуть' : 'Развернуть' }}
      </small>

      <!-- Images -->
      <div v-if="item.links && item.links.length" class="item-post__body-media mb-10 mt-10">
        <BaseGrid :media="item.links" />
      </div>

      <!-- Docs -->
      <div v-if="item.documents && item.documents.length" class="item-post__body-docs grid box overflow-h mb-10 z0">
        <ItemFile
          class="border-inside"
          v-for="file of item.documents"
          :key="file.id"
          :item="file"
          :btns="{ download: true, select: false, remove: false }"
        />
      </div>

      <!-- Stat -->
      <div class="item-post__body-stat flex ai-c jc-sb ggap-10">
        <div class="flex ai-c ggap-10 relative">
          <div class="big-block" v-if="likedShow" @mouseenter="showLikes()" @mouseleave="likedShow = false">
            <div
              class="liked-block pointer flex jc-c ai-c ggap-10"
              @click.stop="$busEmit('view', { url: 'post/Liked.vue', options: item.liked_user })"
            >
              <div class="image-group flex jc-c ai-c">
                <img
                  :src="image.avatar"
                  class="mini-photo"
                  v-show="index >= 0 && index <= 2"
                  :style="{ 'margin-left': '-8px', 'z-index': index }"
                  :key="image?.id + item?.liked_user?.length"
                  v-for="(image, index) in item.liked_user"
                />
              </div>
              <div class="flex ai-c jc-c p-10 counter-circle" v-if="item?.liked_user?.length > 3">
                +{{ item.liked_user.length - 3 }}
              </div>
            </div>
          </div>
          <button
            class="btn rounded"
            :class="isLiked ? 'secondary' : 'grey'"
            @click.prevent="toggleLike"
            @mouseenter="showLikes()"
            @mouseleave="likedShow = false"
          >
            <BaseIcon class="ic-16" :class="isLiked ? 'white' : 'black'" icon="heart" />
            <span v-if="likeCount">{{ likeCount }}</span>
          </button>
          <button class="btn grey rounded" @click="isNewComment = !isNewComment">
            <BaseIcon class="ic-16 black" icon="comment-circle" />
            <span v-if="item.comments_count">{{ item.comments_count }}</span>
          </button>
        </div>

        <small class="flex ai-c ggap-5">
          <BaseIcon class="ic-16 black" icon="eye" />
          <span>{{ item.views || 0 }}</span>
        </small>
      </div>
    </div>

    <!-- Foot -->
    <div v-if="(itemModel.comments && itemModel.comments.length) || isNewComment" class="item-post__foot p-10 b-t">
      <BaseLoad v-if="isCommentsFullLocal && isLoadComments" class="rel sm" v-tippy="'Идет загрузка коментариев'" />
      <Comments
        v-if="itemModel.comments && itemModel.comments.length && itemModel.comments[0]"
        :items="itemModel.comments"
        :postId="item.id"
        @mainFormClose="mainFormClose"
        @updatePost="updateAction(true)"
      />
      <button
        v-if="!isCommentsFull && item.comments_count > commentsLength"
        class="btn sm grey w-100 jc-c"
        @click.prevent="getCommentsList"
      >
        <BaseLoad v-if="isLoadCommentsLocal" class="rel sm" v-tippy="'Идет загрузка коментариев'" />
        показать все комментарии
      </button>
      <CommentsForm
        v-if="isAllChildsClosed"
        :postId="item.id"
        @updatePost="updateAction(true)"
        :class="{ 'mt-10': itemModel.comments && itemModel.comments.length, sticky: isCommentsFull }"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { defineEmits, computed, defineProps, onMounted, ref, toRefs, inject } from 'vue'
import { BaseLoad, BaseIcon, BaseGrid, BaseDotMenu, ItemFile, Comments, CommentsForm } from '@/components'
import { getLinkData, formatRuDateTime, cutFullName, $busEmit, copyTextToClipboard, socketConnect } from '@/plugins'
import comments from '@/api/modules/comments'
import posts from '@/api/modules/posts'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:item', 'updateSingle', 'updateComments'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  isCommentsFull: {
    type: Boolean,
    default: false
  },
  isLoadComments: {
    type: Boolean,
    default: false
  },
  link: {
    type: String,
    default: ''
  }
})

// Data
const { item, isCommentsFull, isLoadComments, link } = toRefs(props)
const profileLink = inject('profileLink')
const router = useRouter()
const store = useStore()
const isCopied = ref(false)
const isUnwrapped = ref(false)
const isDelete = ref(false)
const likedShow = ref(false)
const isLoad = ref(false)
const isNewComment = ref(false)
const isCommentsFullLocal = ref(false)
const isLoadCommentsLocal = ref(false)
const isAllChildsClosed = ref(true)
const likeCount = ref(0)
const isLiked = ref(false)
const avatar = ref('')

// Computed
const profile = computed(() => store.getters.profile)
const canUnwrap = computed(() => {
  const el = document.createElement('div')
  el.innerHTML = DOMPurify.sanitize(item.value.body)
  return item.value?.body?.length > 480 || el.querySelectorAll('p').length > 6
})

const itemModel = computed({
  get: () => item.value,
  set: (val) => emits('update:item', val)
})
const commentsLength = computed(() => {
  let res = 0

  if (itemModel.value?.comments?.length) {
    for (let i = 0; i < itemModel.value.comments.length; i++) {
      const element = itemModel.value.comments[i]
      const count = i + 1
      res = res + count

      if (element?.child?.length) {
        for (let index = 0; index < element.child.length; index++) {
          const count = index + 1
          res = res + count
        }
      }
    }
  }

  return res
})

// Created
likeCount.value = item.value.likes
isLiked.value = item.value.is_liked
isNewComment.value = isCommentsFull.value
isCommentsFullLocal.value = isCommentsFull.value
avatar.value = item.value?.owner?.avatar || require('@/assets/img/no-photo.jpg')
if (isCommentsFull.value) {
  wsConnect()
}

// Mounted
onMounted(() => getLinkData())

// Methods
function toggleLike() {
  isLiked.value = !isLiked.value
  likeCount.value = isLiked.value ? likeCount.value + 1 : likeCount.value - 1

  posts.toggleLike(item.value.id).then(({ data }) => {
    updateAction()
    likeCount.value = data.data.likes
    isLiked.value = data.data.is_liked
  })
}
function showLikes() {
  if (item.value?.liked_user?.length === 1) {
    if (item.value?.liked_user[0].id !== profile.value.id) {
      likedShow.value = true
    }
  } else if (item.value?.liked_user?.length > 1) {
    likedShow.value = true
  }
}

function setImage() {
  avatar.value = require('@/assets/img/no-photo.jpg')
}
function rePublish() {
  store.dispatch('news/CREATE', item.value)
}
function deleteAction() {
  isLoad.value = true
  store.dispatch('news/DELETE', item.value.id)
}
function updateAction(isGetFull) {
  if (isCommentsFullLocal.value) {
    emits('updateComments')
    isAllChildsClosed.value = true
  }
  store.dispatch('news/GET_ITEM', item.value.id)
  if (isGetFull) getCommentsList()
}
function openPost() {
  if (link.value) router.push(`${link.value}/${item.value.id}`)
}
function mainFormClose() {
  const res = []

  if (item.value?.comments?.length) {
    for (let i = 0; i < item.value.comments.length; i++) {
      const element = item.value.comments[i]
      res.push(!!element.reply)

      if (element?.child?.length) {
        res.push(element.child.some((el) => !!el.reply))
      }
    }
  }

  isAllChildsClosed.value = !res.some((el) => el)
}
function getCommentsList() {
  isLoadCommentsLocal.value = true
  isCommentsFullLocal.value = true
  comments
    .getAll(item.value.id)
    .then(({ data }) => {
      item.value.comments = data.data
    })
    .finally(() => (isLoadCommentsLocal.value = false))
}
async function shareAction() {
  const link = window.location.href.includes('/' + String(item.value.id))
    ? window.location.href
    : `${window.location.href}/${item.value.id}`
  isCopied.value = await copyTextToClipboard(link)
  $busEmit('setToast', {
    type: 'green',
    icon: 'check-circle',
    message: `Ссылка на <a href="${link}">пост</a> успешно скопированна!`
  })
}

// Methods:websocket
function wsConnect() {
  const name = `private-comment.${item.value.id}`
  socketConnect.channel(name).listen('.CommentCreatedPrivateEvent', () => {
    getCommentsList()
  })
}
</script>

<style lang="scss">
.counter-circle {
  height: 24px;
  min-width: 24px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.5);
}
.big-block {
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 99;
  padding: 20px 0;
}
.liked-block {
  padding: 10px 10px 10px 18px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50px;
}
.wrapped {
  // display: -webkit-box;
  // -webkit-line-clamp: 4;
  // -webkit-box-orient: vertical;
  height: 120px;
  overflow: hidden;
}
.item-post {
  position: relative;
  z-index: 0;

  &:hover {
    z-index: 1;
  }

  &__delete {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
    border-radius: var(--br);

    &-box {
      position: sticky;
      top: 70px;
      z-index: 0;
    }
  }

  &__user {
    a {
      color: var(--text);
    }
    small {
      color: var(--grey-ed);
    }
  }

  &__menu {
    position: relative;
    z-index: 2;

    &:hover &-btn {
      background-color: var(--grey);
    }

    &-body {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      box-shadow: var(--box-shadow);
    }

    &:hover &-body {
      display: grid;
    }
  }

  &__body {
    &-text {
      overflow-wrap: anywhere;
      // overflow-wrap: break-word;
      // word-wrap: break-word;

      p {
        margin: 10px 0;
      }
    }

    &-stat {
      small {
        opacity: 0.5;
      }
    }

    &-media {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
</style>
