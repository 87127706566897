<template>
  <form class="base-head-search" v-click-outside="closeAction">
    <label for="s" class="base-head-search__label flex ai-c jc-c">
      <BaseLoad v-if="isLoad" class="rel sm" />
      <BaseIcon v-else class="ic-16 grey" icon="search" />
    </label>
    <input
      type="search"
      name="s"
      id="s"
      class="base-head-search__input w-300"
      placeholder="Поиск по сотрудникам"
      autocomplete="off"
      :data-btn="uniqId"
      v-model="searchText"
    />

    <div class="base-head-search__body box p-5 overflow-a grid" v-if="users.length">
      <BaseLoad v-if="isLoad" class="white-transparent" />
      <a :href="profileLink + item.id" v-for="item of users" :key="item.id" class="btn sm transparent-primary p-5">
        <img :src="item.avatar || require('@/assets/img/no-photo.jpg')" class="photo-item" />
        {{ item.name }}
      </a>
    </div>
  </form>
</template>

<script setup>
import { debounce } from 'lodash'
import { useStore } from 'vuex'
import { ref, computed, watch, inject } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'

// Data
const store = useStore()
const isLoad = ref(false)
const isOpen = ref(false)
const searchText = ref('')
const uniqId = 'ddown-search'
// const direction = ''

// Computed
const users = computed(() => store.getters['helpers/globalUsers'])
const profileLink = inject('profileLink')

// Watch
watch(
  searchText,
  debounce(
    () => {
      searchUsers(searchText.value)
    },
    searchText.value.length ? 500 : 1
  )
)

// Methods
async function searchUsers(searchText) {
  try {
    isLoad.value = true
    await store.dispatch('helpers/GLOBAL_SEARCH_USERS', searchText.trim())
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
function closeAction() {
  isOpen.value = false
}
</script>

<style lang="scss" scoped>
.base-head-search {
  position: relative;

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
  }

  &__input {
    border: 0;
    border-radius: var(--br);
    background-color: var(--form);
    height: 36px;
    padding: 0 10px 0 40px;

    &:hover {
      box-shadow: 0 0 0 1px var(--bcolor);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--secondary);
    }
  }

  &__body {
    position: fixed;
    z-index: 2001;
    box-shadow: var(--box-shadow);
    max-height: calc(100vh - 300px);
    min-height: 100px;
  }
  .photo-item {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    object-fit: cover;
  }
}
</style>
