import axios from '../axios'

class CommunitiesApi {
  getAll(params) {
    if (params) return axios.get('/api/community', { params })
    else return axios.get('/api/community')
  }
  getAllMy(params) {
    if (params) return axios.get('/api/user/community/super-admin', { params })
    else return axios.get('/api/user/community/super-admin')
  }
  getWhereAdmin() {
    return axios.get('/api/user/community/admin')
  }
  getAllSubscribed(params) {
    if (params) return axios.get('/api/user/community/member', { params })
    else return axios.get('/api/user/community/member')
  }
  get(id) {
    return axios.get(`/api/community/${id}`)
  }
  getCommunityTags() {
    return axios.get('/api/tags/key/communities')
  }
  create(payload) {
    return axios.post('/api/community', payload)
  }
  update(payload, id) {
    return axios.post(`/api/community/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/community/${id}`)
  }
  subscribe(payload) {
    return axios.post('/api/user/community/add', payload)
  }
  unsubscribe(id) {
    return axios.delete(`/api/user/community/delete/${id}`)
  }
}

export default new CommunitiesApi()
