import { notifications, communities, comments } from '@/api'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    pages: null,
    count: 0
  }),
  mutations: {
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    CHANGE_ITEM(state, { id, payload }) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    SET_COUNT(state, payload) {
      state.count = payload
    }
  },
  actions: {
    GET_ROWS({ commit }, params) {
      return notifications.getAll(params).then(async ({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        commit('SET_PAGES', pages)

        const rows = []
        for (let i = 0; i < data.data.length; i++) {
          const item = data.data[i]
          try {
            if (item?.objects_id?.community) {
              const community = await communities.get(item?.objects_id?.community)
              rows.push({
                ...item,
                objects_id: {
                  community: community?.data?.data || {
                    id: 0,
                    title: 'Не известно'
                  }
                }
              })
            } else if (item?.objects_id?.comment_id) {
              const comment = await comments.get(item?.objects_id?.comment_id)
              rows.push({
                ...item,
                objects_id: {
                  post_id: item?.objects_id?.post_id,
                  comment: comment?.data?.data || null
                }
              })
            } else {
              rows.push(item)
            }
          } catch (error) {
            console.log(error)
            if (item?.objects_id?.community) {
              rows.push({
                ...item,
                objects_id: {
                  community: {
                    id: 0,
                    title: 'Не известно'
                  }
                }
              })
            } else if (item?.objects_id?.comment_id) {
              rows.push({
                ...item,
                objects_id: {
                  post_id: item?.objects_id?.post_id,
                  comment: {
                    body: 'Не известно'
                  }
                }
              })
            } else {
              rows.push(item)
            }
          }
        }
        commit('SET_ROWS', rows)
      })
    },
    GET_COUNT({ commit }) {
      return notifications.count().then(({ data }) => {
        commit('SET_COUNT', data.count)
      })
    },
    READ(_, id) {
      return notifications.readSingle(id)
    },
    READ_ALL({ commit }) {
      return notifications.readAll().then((res) => {
        if (res) commit('SET_COUNT', 0)
      })
    }
  },
  getters: {
    rows: (s) => s.rows,
    pages: (s) => s.pages,
    count: (s) => s.count
  }
}
