import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'

// Modules
import news from './news'
import communities from './communities'
import events from './events'
import meetingRooms from './meeting-rooms'
import meets from './meets'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "views-redirect" */ '@/views/redirect.vue')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layouts-default" */ '@/layouts/Default.vue'),
    children: [...news, ...communities, ...events, ...meetingRooms, ...meets]
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: 'Ошибка'
    },
    component: () => import(/* webpackChunkName: "views-error" */ '@/views/error.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'Соц.сеть'
router.afterEach((to) => {
  nextTick(() => {
    document.title = 'INTRA: ' + to.meta.title || DEFAULT_TITLE
  })
})

export default router
