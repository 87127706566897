import { events, search } from '@/api'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    pages: null
  }),
  mutations: {
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    CONCAT_ROWS(state, payload) {
      state.rows = [...state.rows, ...payload]
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    PUSH_ITEM(state, payload) {
      state.rows.unshift(payload)
    },
    CHANGE_ITEM(state, { id, payload }) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    REMOVE_ITEM(state, id) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1)
    }
  },
  actions: {
    GET_ROWS({ commit }, params) {
      return events.getAll(params).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        commit('SET_PAGES', pages)

        const payload = data.data
        if (!params?.page || Number(params?.page) === 1) commit('SET_ROWS', payload)
        else commit('CONCAT_ROWS', payload)
      })
    },
    SEARCH_ROWS({ commit }, searchText) {
      return search.community(searchText).then(({ data }) => {
        const pages = {
          ...data.meta,
          totalPages: Math.ceil(data.meta.total / data.meta.per_page)
        }
        commit('SET_PAGES', pages)
        commit('SET_ROWS', data.data)
      })
    },
    CHANGE_ITEM({ commit }, id) {
      return events.get(id).then(({ data }) => {
        commit('CHANGE_ITEM', { id, payload: data.data })
      })
    },
    POST(_, payload) {
      const formData = new FormData()
      if (payload?.action === 'update') formData.append('_method', 'PATCH')

      // Required
      formData.append('title', payload.title)
      formData.append('status', payload.status)
      formData.append('date_event', payload.date_event)
      formData.append('date_start_publish', payload.date_start_publish)
      formData.append('date_end_publish', payload.date_end_publish)

      // Not required
      if (payload?.action !== 'update') {
        if (payload.description) formData.append('description', payload.description)
        if (payload.event_location_id) formData.append('event_location_id', payload.event_location_id)
        if (payload.meeting_link) formData.append('meeting_link', payload.meeting_link)
        if (payload.help_text) formData.append('help_text', payload.help_text)
      } else {
        formData.append('description', payload.description)
        formData.append('event_location_id', payload.event_location_id)
        formData.append('meeting_link', payload.meeting_link)
        formData.append('help_text', payload.help_text)
      }

      if (payload?.action === 'update') {
        return events.update(formData, payload.id)
      } else {
        return events.create(formData)
      }
    },
    DELETE({ commit }, id) {
      return events.delete(id).then(() => {
        commit('REMOVE_ITEM', id)
      })
    }
  },
  getters: {
    rows: (s) => s.rows,
    pages: (s) => s.pages
  }
}
