<template>
  <component
    v-if="currentComponent"
    :is="currentComponent"
    :currentId="currentId"
    :currentOptions="currentOptions"
    @close="closeAction"
  />
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { $busOn } from '@/plugins'

// Data
const currentId = ref(0)
const currentOptions = ref(0)
const componentUrl = ref('')
const currentComponent = computed(() =>
  componentUrl.value ? defineAsyncComponent(() => import(`../${componentUrl.value}`)) : ''
)

// Created
$busOn('add', (url) => {
  componentUrl.value = url
})
$busOn('edit', ({ url, id, options }) => {
  componentUrl.value = url
  currentId.value = id
  currentOptions.value = options
})
$busOn('view', ({ url, id, options }) => {
  componentUrl.value = url
  currentId.value = id
  currentOptions.value = options
})

// Methods
function closeAction() {
  componentUrl.value = ''
  currentId.value = 0
  currentOptions.value = {}
}
</script>
