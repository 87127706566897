import { meetingRooms } from '@/api'
import moment from 'moment'
import { $busEmit } from '../../plugins'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    pages: null,
    currentPage: 1,
    searchText: localStorage.getItem('searchText') || '',
    date: moment().format('YYYY-MM-DD'),
    capacity: localStorage.getItem('searchCapacity') || '',
    selectedTags: localStorage.getItem('searchTags') ? JSON.parse(localStorage.getItem('searchTags')) : []
  }),
  mutations: {
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    CONCAT_ROWS(state, payload) {
      state.rows = [...state.rows, ...payload]
    },
    SET_PAGES(state, payload) {
      state.pages = payload
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload
    },
    PUSH_ITEM(state, payload) {
      state.rows.unshift(payload)
    },
    CHANGE_ITEM(state, { id }) {
      meetingRooms.get(id).then(({ data }) => {
        const index = state.rows.findIndex((el) => el?.id === id)
        if (index !== -1) state.rows.splice(index, 1, data.data)
      })
    },
    REMOVE_ITEM(state, id) {
      const index = state.rows.findIndex((el) => el.id === id)
      if (index !== -1) state.rows.splice(index, 1)
    },
    CHANGE_BOOKING_ITEM(state, payload) {
      const index = state.rows.findIndex((el) => el.id === payload.negotiation_id)
      const idx = state.rows[index]?.booking.findIndex((el) => el.id === payload.id)
      if (index !== -1 && idx !== -1) {
        state.rows[index].booking.splice(idx, 1, payload)
      }
    },
    PUSH_BOOKING_ITEM(state, payload) {
      if (moment(payload.data.date_start).format('YYYY-MM-DD') === moment(state.date).format('YYYY-MM-DD')) {
        const index = state.rows.findIndex((el) => el.id === payload.negotiation_id)
        state.rows[index].booking.unshift(payload.data)
      }
    },
    DELETE_BOOKING_ITEM(state, payload) {
      const index = state.rows.findIndex((el) => el.id === payload.negotiation_id)
      const idx = state.rows[index]?.booking.findIndex((el) => el.id === payload.id)
      if (index !== -1 && idx !== -1) {
        state.rows[index]?.booking.splice(idx, 1)
      }
    },
    SET_SEARCH_TEXT(state, payload) {
      state.searchText = payload
      localStorage.setItem('searchText', payload.value)
    },
    SET_SEARCH_DATE(state, payload) {
      state.date = payload
      if (payload) {
        localStorage.setItem('searchDate', payload)
      } else {
        localStorage.setItem('searchDate', moment().format('YYYY-MM-DD'))
      }
    },
    SET_SEARCH_CAPACITY(state, payload) {
      state.capacity = payload
      localStorage.setItem('searchCapacity', payload)
    },
    SET_SEARCH_TAGS(state, payload) {
      state.selectedTags = payload
      localStorage.setItem('searchTags', JSON.stringify(payload))
    }
  },
  actions: {
    GET_ROWS({ commit, state }) {
      return meetingRooms
        .getAll(state.currentPage, state.searchText, state.date, state.capacity, state.selectedTags)
        .then(({ data }) => {
          const pages = { ...data?.meta }
          commit('SET_PAGES', pages)
          commit('SET_CURRENT_PAGE', pages?.current_page)
          commit('SET_ROWS', data.data)
          $busEmit('updateBooking')
        })
    },
    CHANGE_ITEM({ commit }, id) {
      return meetingRooms.get(id).then(({ data }) => {
        commit('CHANGE_ITEM', { id, payload: data.data })
      })
    },
    POST(_, payload) {
      const formData = new FormData()
      if (payload?.action === 'update') formData.append('_method', 'PATCH')

      // Required
      formData.append('name', payload.name)
      formData.append('capacity', payload.capacity)
      formData.append('max_capacity', payload.max_capacity)
      formData.append('location', payload.location)
      if (payload?.image?.file) formData.append('image', payload.image.file)
      if (payload?.secondary_image?.file) formData.append('secondary_image', payload.secondary_image.file)

      if (payload?.tags?.length) {
        for (let i = 0; i < payload.tags.length; i++) {
          const tag = payload.tags[i]
          formData.append('tags[]', tag.name)
        }
      }

      if (payload?.action === 'update') {
        return meetingRooms.update(formData, payload.id)
      } else {
        return meetingRooms.create(formData)
      }
    },
    DELETE({ commit }, id) {
      return meetingRooms.delete(id).then(() => {
        commit('REMOVE_ITEM', id)
      })
    },
    ADD_BOOKING(_, { payload }) {
      return meetingRooms.addBooking(payload)
    },
    UPDATE_BOOKING(_, { id, payload }) {
      return meetingRooms.updateBooking(id, payload)
    },
    DELETE_BOOKING(_, { id, all }) {
      return meetingRooms.deleteBooking(id, all)
    }
  },
  getters: {
    rows: (s) => s.rows,
    pages: (s) => s.pages,
    date: (s) => s.date,
    capacity: (s) => s.capacity,
    selectedTags: (s) => s.selectedTags,
    searchText: (s) => s.searchText
  }
}
