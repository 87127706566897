<template>
  <div class="base-alert grid ai-fs gtc-auto-1 ggap-10 p-10 box" :class="type">
    <div class="base-alert__icon flex">
      <BaseIcon class="ic-20" :icon="getIcon" />
    </div>
    <div class="base-alert__message">
      <slot>Some alert text</slot>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, toRefs } from 'vue'
import { BaseIcon } from '@/components'

// Props
const props = defineProps({
  type: {
    type: String,
    default: 'info'
  }
})

// Data
const { type } = toRefs(props)

// Computed
const getIcon = computed(() => {
  switch (type.value) {
    case 'info':
      return 'alert'

    case 'yellow':
      return 'alert'

    default:
      return 'close'
  }
})
</script>

<style lang="scss" scoped>
.base-alert {
  border: 0;
  border-left: 4px var(--bcolor) solid;
  border-radius: 4px var(--br) var(--br) 4px;
  background-color: var(--alert);
  color: var(--text);

  &.info {
    // color: var(--primary);
    border-color: var(--primary);

    svg {
      fill: var(--primary);
    }
  }

  &.yellow {
    // color: var(--yellow);
    border-color: var(--yellow);

    svg {
      fill: var(--yellow);
    }
  }

  &__message {
    padding-top: 2px;
  }
}
</style>
