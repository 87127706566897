export default [
  {
    path: 'events',
    component: () => import(/* webpackChunkName: "views-events" */ '@/views/events/index.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Мероприятия'
        },
        component: () => import(/* webpackChunkName: "views-events" */ '@/views/events/list.vue')
      },
      {
        path: 'archive',
        meta: {
          title: 'Архив мероприятий'
        },
        component: () => import(/* webpackChunkName: "views-events-archive" */ '@/views/events/list.vue')
      },
      {
        path: 'single/:id',
        meta: {
          title: 'Мероприятие'
        },
        component: () => import(/* webpackChunkName: "views-events-single" */ '@/views/events/single.vue')
      }
    ]
  }
]
