<template>
  <div class="form-item grid" :class="{ required: required, disabled: disabled }" :data-success="isSuccess">
    <div v-if="label" class="flex ai-c jc-sb">
      <label class="form-item__label">
        <BaseIcon v-if="helper" class="form-item__help ic-14 grey" icon="alert" v-tippy="helper" />
        {{ label }}
      </label>
      <small v-if="maxlength" class="fs-12">
        <b :class="{ 't-red': propValue.length > maxlength }">
          {{ propValue.length }}
        </b>
        /{{ maxlength }}
      </small>
    </div>

    <div
      class="textarea form-item__input grid pl-10 pos-r"
      :class="{ focused: focused, 'pr-30': showCounter, 'pr-10': !showCounter }"
      @click.prevent="setFocus"
    >
      <small v-if="showCounter && propValue.length" class="textarea__counter pos-a z1" v-tippy="'Осталось символов'">
        {{ propValue ? maxlength - propValue.length : 0 }}
      </small>
      <textarea
        ref="textarearef"
        :name="'form-item-' + uid"
        :id="'textarea-' + uid"
        cols="30"
        :rows="rows"
        class="textarea__input"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :maxlength="maxlength"
        v-model.trim="propValue"
        @focus="focused = true"
        @blur="focused = false"
        @input="resizer"
        @change="resizer"
      />
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, computed, ref, nextTick, onMounted, watch } from 'vue'
import { BaseIcon } from '@/components'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['update:modelValue', 'enterAction'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  rows: {
    type: String,
    default: '1'
  },
  placeholder: {
    type: String,
    default: ''
  },
  helper: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  showCounter: {
    type: Boolean,
    default: false
  },
  maxlength: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: ''
  }
})

// Data
const { label, placeholder, required, helper, showCounter, maxlength, modelValue } = toRefs(props)
const uid = getCurrentInstance().uid
const focused = ref(false)
const textarearef = ref()

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => {
    let str = val.replace('<p>&nbsp;</p>', '')
    str = DOMPurify.sanitize(str)
    emits('update:modelValue', str)
  }
})
const isSuccess = computed(() => {
  return !!propValue.value
})

// Watch
watch(propValue, (val) => {
  if (!val.length) {
    nextTick(() => {
      resizer()
    })
  }
})

// Created
nextTick(() => resizer())

// Mounted
onMounted(() => {
  const editor = document.getElementById('textarea-' + uid)

  editor.addEventListener('keydown', function (event) {
    if (event.ctrlKey && (event.key === 'Enter' || event.code === 'Enter' || event.keyCode === 13)) {
      emits('enterAction')
    }
  })
})

// Methods
function resizer() {
  const el = document.querySelector('#textarea-' + uid)
  if (el) {
    el.style.height = 'auto'
    el.style.height = el.scrollHeight + 'px'
  }
}
function setFocus() {
  textarearef.value.focus()
}
</script>

<style lang="scss" scoped>
.textarea {
  max-height: 200px;
  overflow: auto;

  &__input {
    width: 100%;
    resize: none;
    border: 0;
    background-color: transparent;
    margin: 8px 0;
    overflow: hidden;
  }

  &__counter {
    right: 2px;
    bottom: 2px;
    background-color: var(--form);
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
  }
}
</style>
