<template>
  <button :class="[isDarkMode ? 'dark-theme' : 'light-theme']" class="switch-theme" @click="changeTheme">
    <BaseIcon class="ic-14" :class="{ white: isDarkMode }" :icon="isDarkMode ? 'moon' : 'sun'" />
  </button>
</template>

<script setup>
import { BaseIcon } from '@/components'
import { ref } from '@vue/reactivity'
import { $busEmit } from '../../../plugins'

const isDarkMode = ref(false)
if (localStorage.getItem('dark-mode')) {
  changeTheme()
}

// Methods
function changeTheme() {
  isDarkMode.value = !isDarkMode.value
  $busEmit('switchTheme', !isDarkMode.value)
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
    localStorage.setItem('dark-mode', isDarkMode.value)
  } else {
    document.documentElement.classList.remove('dark')
    localStorage.removeItem('dark-mode')
  }
}
</script>

<style lang="scss" scoped>
.switch-theme {
  $parent: &;
  position: relative;
  width: 30px;
  height: 30px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 0;
  cursor: pointer;
}
.dark-theme {
  background: #333;
}
.light-theme {
  background: #eee;
}
</style>
