import axios from '../axios'
import moment from 'moment'

class MeetingRoomsApi {
  getTags() {
    return axios.get('/api/tags/key/negotiations')
  }
  create(payload) {
    return axios.post('/api/negotiation', payload)
  }
  update(payload, id) {
    return axios.post(`/api/negotiation/${id}`, payload)
  }
  get(id) {
    return axios.get(`/api/negotiation/${id}`)
  }
  delete(id) {
    return axios.delete(`/api/negotiation/${id}`)
  }
  getAll(page, searchText = '', date, capacity, tags = []) {
    let url = `/api/negotiation?page=${page}`
    if (tags.length) for (let i = 0; i < tags.length; i++) url += `&tags[]=${tags[i]}`
    if (searchText) url += `&s=${searchText}`
    if (date) url += `&date=${date == null ? moment().format('YYYY-MM-DD') : date}`
    if (capacity) url += `&capacity=${capacity}`
    return axios.get(url)
  }
  addBooking(payload) {
    return axios.post('/api/negotiation/booking', payload)
  }
  updateBooking(id, payload) {
    return axios.post(`/api/negotiation/booking/${id}`, payload)
  }
  deleteBooking(id, all) {
    return axios.delete(`/api/negotiation/booking/${id}${all ? '?all=1' : ''}`)
  }
  getFreeMeetingRooms({ repeat_type, date_start, date_end, end_of_booking, negotiation_id, booking_id }) {
    return axios.get(`/api/negotiation/free-negotiations/booking?repeat_type=${repeat_type}
      &date_start=${date_start}
      &date_end=${date_end}
      &end_of_booking=${end_of_booking}
      &negotiation_id=${negotiation_id}
      ${booking_id ? '&booking_id=' + booking_id : ''}
    `)
  }
}

export default new MeetingRoomsApi()
