<template>
  <div class="base-preview p-5 box" :class="{ isBottom: isBottom }">
    <div v-if="item" class="grid gtc-auto-1 ggap-10 ai-c pr-10">
      <div class="cover ava-50 bordered">
        <img :src="avatar" :onerror="setImage" />
      </div>

      <div class="grid ggap-5">
        <div class="flex ai-c ggap-5">
          <h4 class="title">{{ item.name }}</h4>
          <template v-if="item.type !== 'community'">
            <small v-if="item.status" class="marker" :class="item.status.color">{{ item.status.name }}</small>
            <small v-else class="marker green">На работе</small>
          </template>
        </div>
        <small v-if="item.type === 'community'" class="flex ai-c ggap-10 fs-12 t-grey-ed">
          <span class="flex ai-c ggap-5">
            <BaseIcon class="ic-14 grey" icon="users" />
            {{ item.count - 1 }}
          </span>
          <span class="dot" />
          <span v-for="tag of item.tags" :key="tag.id">#{{ tag.name }}</span>
        </small>
        <small v-else class="fs-12 t-grey-ed">
          {{ item.specialization || 'Безработный(ая)' }} в команде: <a href="#">{{ item.company || 'Ронинов' }}</a>
        </small>
      </div>
    </div>
    <template v-else>
      <BaseLoad class="rel sm pl-10 pr-10 pt-5 pb-5" />
    </template>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, defineProps, nextTick, onMounted, ref } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'

const props = defineProps({
  hash: {
    type: String,
    default: ''
  },
  coords: {
    type: Object,
    default: () => ({})
  }
})

// Data
const store = useStore()
const isBottom = ref(false)
const item = ref(null)
const avatar = ref('')

// Computed
const users = computed(() => store.getters['helpers/users'])
const communities = computed(() => store.getters['helpers/communities'])

// Created
getItem()

// Mounted
onMounted(() => {
  nextTick(() => {
    calcPosition()
  })
})

// Methods
function calcPosition() {
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  const coordsBtn = props.coords
  const bodyElement = document.querySelector(`.base-preview`)

  if (bodyElement) {
    const coordsBody = bodyElement.getBoundingClientRect()
    isBottom.value = vh / 2 > coordsBtn.bottom
    bodyElement.style.top =
      vh / 2 > coordsBtn.bottom ? coordsBtn.bottom + 10 + 'px' : coordsBtn.top - coordsBody.height - 10 + 'px'

    bodyElement.style.left = coordsBtn.left + coordsBtn.width / 2 - coordsBody.width / 2 + 'px'
  }
}
async function getItem() {
  if (props.hash.includes('user')) {
    const arr = props.hash.split('#user-')
    const userID = arr[1]
    if (userID) {
      if (users.value?.length) item.value = users.value?.find((el) => el.id === Number(userID))
      if (!item.value) {
        await store.dispatch('helpers/GET_USER', userID)
        item.value = users.value?.find((el) => el.id === Number(userID))
        await nextTick()
        calcPosition()
      }
      avatar.value = item.value?.avatar || require('@/assets/img/ava.svg')
    }
  } else {
    const arr = props.hash.split('#community-')
    const communityID = arr[1]
    if (communityID) {
      if (communities.value?.length) item.value = communities.value?.find((el) => el.id === Number(communityID))
      if (!item.value) {
        await store.dispatch('helpers/GET_COMMUNITY', communityID)
        item.value = communities.value?.find((el) => el.id === Number(communityID))
        await nextTick()
        calcPosition()
      }
      avatar.value = item.value?.avatar || require('@/assets/img/no-photo.jpg')
    }
  }
}
function setImage() {
  avatar.value = require('@/assets/img/no-photo.jpg')
}
</script>

<style lang="scss">
.base-preview {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  box-shadow: var(--box-shadow);
  pointer-events: none;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
  }

  &::after {
    top: 100%;
    border-top: 10px solid var(--box);
  }
  &::before {
    top: calc(100% + 1px);
    border-top: 10px solid var(--bcolor);
  }

  &.isBottom {
    &::after {
      top: -20px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid var(--box);
    }
    &::before {
      top: -21px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid var(--bcolor);
    }
  }
}
</style>
