<template>
  <div v-for="(day, index) in data" :key="index" class="w-100">
    <div class="day-item p-10">
      <div class="fw-b fs-18">
        {{ formatDate(index) }}
      </div>
      <div
        class="event-item"
        :class="{ pointer: profile?.id === event?.owner?.id }"
        v-for="event in day"
        :key="event?.id + event?.negotiation?.name"
        @click="open(event)"
      >
        <div class="fw-b cut">{{ event?.name }}</div>
        <div class="flex ggap-10 ai-c">
          <span>{{ moment(event?.date_start).format('HH:mm') }} - {{ moment(event?.date_end).format('HH:mm') }}</span>
          <span class="dot"></span>
          <span>{{ event?.negotiation?.name }}</span>
        </div>
        <div class="flex ai-c ggap-10">
          <div class="image-group">
            <BaseUsers :items="[event?.owner, ...event?.user?.slice(0, 5)]" />
          </div>
          <div>
            {{ event?.user?.length + 1 }}
            {{ event?.user?.length + 1 === 1 ? 'участник' : 'участника' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'
import moment from 'moment'
import { BaseUsers } from '@/components'
import { $busEmit } from '@/plugins'
import momentDurationFormatSetup from 'moment-duration-format'
import { useStore } from 'vuex'

// Props
const props = defineProps({
  events: {
    type: Object,
    default: () => ({})
  }
})

// Data
momentDurationFormatSetup(moment)
const { events } = toRefs(props)
const store = useStore()

// computed
const profile = computed(() => store.getters.profile)
const data = computed(() => {
  let array = {}
  for (let i = 0; i < events.value.length; i++) {
    const date = moment(events.value[i].date_start).format('YYYY-MM-DD')
    if (!array[date]) array[date] = []
    array[date].push(events.value[i])
  }
  return array
})

// functions
function formatDate(date) {
  moment.locale('ru')
  const zero = moment(`${moment(date).format('YYYY-MM-DD')} 0:00 AM`)
  const now = moment(`${moment().format('YYYY-MM-DD')} 0:00 AM`)
  const diff = zero.diff(now, 'days')
  switch (diff) {
    case 0:
      return `Сегодня • ${getWeekDay(date)}, ${moment(date).format('YYYY-MM-DD')}`
    case 1:
      return `Завтра • ${getWeekDay(date)}, ${moment(date).format('YYYY-MM-DD')}`
    default:
      return moment(date).format('YYYY-MM-DD')
  }
}

function open(item) {
  if (profile.value?.id === item?.owner?.id) {
    const obj = { ...item, negotiation_id: item.negotiation?.id }
    $busEmit('edit', { url: 'meeting-rooms/booking.vue', id: { booking: obj, meetingRoomId: item.negotiation?.id } })
  }
}

function getWeekDay(date) {
  const time = moment(date).format('dd')
  return time[0].toUpperCase() + time[1]
}
</script>

<style>
.event-item {
  border: 1px solid var(--primary-el);
  border-left: 4px solid var(--primary);
  padding: 12px 10px;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--box);
}
</style>
